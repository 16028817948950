import React from 'react';
import { Route } from 'react-router-dom';
import MyProfile from '../components/MyProfile/My Profile'
import Instructions from '../components/Instructions/index'
import  Prices from '../components/Prices/index'

export default [
    <Route exact path="/myprofile" component={MyProfile} />,
    <Route exact path="/instructions" component={Instructions} />,
    <Route exact path="/prices" component={Prices} />
];