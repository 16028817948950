import * as React from "react";
import { List, Datagrid,Toolbar, SaveButton, usePermissions, TextField, ReferenceInput, SelectInput, NumberInput, SimpleList, DateField, NumberField, SimpleForm, TextInput, Edit, Filter } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { DateInput } from '../components/DateInput'
import { userContext } from '../context/Context'

const TitlePayments = ({ record }) => {
    return <span>Plaćanje</span>;
};

const PaymentFilter = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();
    return (
        <Filter {...props}>
            <TextInput source="_q" label="Pretraži" alwaysOn />
            <DateInput source='created_at_gte' label="Od" alwaysOn={isSmall ? false : true} options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />
            <DateInput source='created_at_lte' label="Do" alwaysOn={isSmall ? false : true} options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />
            {permissions === 'SuperAdmin' && <ReferenceInput label="Trener" source="coach" reference="users" filter={{ role_ncontains: 2 }}>
                <SelectInput optionText="name" optionValue='id' />
            </ReferenceInput>}
        </Filter>
    )
};

export const PaymentList = ({ permissions, ...props }) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [userMe] = React.useContext(userContext)

    return (
        <List title={<TitlePayments />} bulkActionButtons={false} sort={{ field: 'created_at', order: 'DESC' }} filter={permissions !== 'SuperAdmin' ? { coach: userMe && userMe.id } : {}} filters={<PaymentFilter />}  {...props}>
            {isSmall ?
                <SimpleList
                    primaryText={record => record && record.description + ' RSD'}
                    secondaryText={record => record && moment(record.startDate).format('D/MM/YYYY') + ' do ' + moment(record.endDate).format('D/MM/YYYY')}
                    tertiaryText={record => record && moment(record.created_at).format('D/MM/YYYY HH:mm')}
                />
                :
                <Datagrid rowClick="edit">
                    <NumberField source="amount" label="Iznos" />
                    <TextField source="description" label="Opis" />
                    <DateField source="created_at" locales="fr-FR" label="Kreirano" />
                    <DateField source="startDate" locales="fr-FR" label="Važi od" />
                    <DateField source="endDate" locales="fr-FR" label="Važi do" />
                    <TextField source="coach.name" label="Trener" sortable={false} />
                    {/*  <TextField source="fiscalNumber" label="Fiscal" />*/}
                </Datagrid>}
        </List>
    )
}

export const PaymentEdit = props => (
    <Edit {...props} undoable={false}>
        <SimpleForm toolbar={<ActionsSave />}>
            <NumberInput source="amount" label="Iznos" />
            <TextInput source="description" label="Opis" />
            <DateInput source="startDate" options={{ format: 'DD/MM/YYYY' }} inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }} label="Važi od" />
            <DateInput source="endDate" options={{ format: 'DD/MM/YYYY' }} inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }} label="Važi do" />
            <ReferenceInput label="Student" source="student.id" reference="students" perPage={10000}   sort={{ field: 'firstName', order: 'ASC' }}>
                <SelectInput optionText={(option) => `${option?.firstName} ${option?.lastName}`} optionValue='id' />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

const ActionsSave = props => {
    return (
        <Toolbar {...props} >
            <SaveButton disabled={props.pristine ? true : false}/>
        </Toolbar>
    )
};
