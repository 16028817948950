import React, { createContext, useState } from 'react'

export const userContext = createContext()

export const MyContextProvider = props => {

    const [user, setUser] = useState()
    return (
        <userContext.Provider value={[user, setUser]}>
            {props.children}
        </userContext.Provider>

    )
}


