import { List, Create, SimpleForm, Datagrid, TextField, DateField, TextInput } from 'react-admin';

export const NotificationList = props => (
    <List {...props} bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="message" label={'Poruka'} />
            <TextField source="type" label={'Tip'} />
            <DateField source="created_at" label={'Datum kreiranja'} />
        </Datagrid>
    </List>
);

export const NotificationCreate = (props) => (
    <Create  {...props}>
        <SimpleForm>
            <TextInput source="message" multiline={true} label="Poruka" />
            <TextInput source="type" label="Tip" defaultValue={'info'} disabled/>
        </SimpleForm>
    </Create>
);