import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { newUser } from '../../Services/Services'
import {
    DatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { updateStudent } from '../../Services/Services'
import { userContext } from '../../context/Context'
import { useHistory } from "react-router-dom";
import { useNotify } from 'react-admin';
import Box from '@material-ui/core/Box';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PaymentIcon from '@material-ui/icons/Payment';
import ListPayment from './ListPayment'
import { AttendanceTrainingList } from './ListAttendance'
import { socket } from '../../Layout/MyAppBar'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#42b6ab',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(1, 0, 1),
        backgroundColor: '#42b6ab !important'
    },
    submitDialog: {
        backgroundColor: '#42b6ab !important'
    },
    input: {
        '&::placeholder': {
            color: 'grey',
        },
        'color': 'white'
    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: "#42b6ab !important"
    },
    link: {
        color: "#42b6ab !important"
    },
    formControl: {

    },

}));


export default function MyProfile() {
    const classes = useStyles();
    const [paymentDialog, setPaymentDialog] = React.useState(false)
    const [listDialog, setListDialog] = React.useState(false)
    const [userMe, setUserMe] = React.useContext(userContext)
    const notify = useNotify();
    const [amount, setAmoutT] = React.useState()
    const [debt, setDebt] = React.useState()
    const [validDate, setValidDate] = React.useState()
    const [payed, setPayed] = React.useState()
    const [data, setData] = React.useState({
        firstName: '',
        lastName: '',
        phone: '',
        birthday: '',
    })

    const setAmountDebtTrainigns = (amount, debt) => {
        setAmoutT(
            amount
        )
        setDebt(debt)
    }


    React.useEffect(() => {
        if (userMe) {
            setAmountDebtTrainigns(userMe.student.amountTraining, userMe.student.debtTraining)
            setValidDate(userMe?.validUntil?.endDate)
            setPayed(userMe?.payed)
        }
        socket.on('amounttraining', (res) => {
            setUserMe(res)
        })

        userMe && setData({
            firstName: userMe?.student?.firstName,
            lastName: userMe?.student?.lastName,
            phone: userMe?.student?.phone,
            birthday: userMe?.student?.birthday,
        })
        //  return () => socket.off('amounttraining');
    }, [])

    React.useEffect(() => {
        setData({
            firstName: userMe?.student?.firstName,
            lastName: userMe?.student?.lastName,
            phone: userMe?.student?.phone,
            birthday: userMe?.student?.birthday,
        })
        if (userMe) {
            setAmountDebtTrainigns(userMe.student.amountTraining, userMe.student.debtTraining)
            setValidDate(userMe?.validUntil?.endDate)
            setPayed(userMe?.payed)
        }

    }, [userMe])

    const ValidMembership = () => {
        if (!validDate) {
            return (<span style={{ color: 'red' }}>{`🎫 Nije uplaćena članarina.`}</span>)
        }

        if (validDate) {

            if (amount <= 0) {
                return (<span style={{ color: 'red' }}>{`🎫 Trenutna članarina je iskorišćena.`}</span>)
            }

            return (`🎫 Važi do: ${moment(validDate).format('DD/MM/YYYY')}`)

        }
    }

    const NextValidMembershop = () => {
        if (payed === false) {
            return (<span> <br /><span style={{ color: 'red' }}>{`🎫 Plaćanje članarine na narednom treningu.`}</span>
            </span>)
        }
    }

    return (

        <Container component="main" maxWidth="xs">

            <div className={classes.paper} tyle={{ backgroundColor: 'white !important' }}>
                <Avatar className={classes.avatar}>
                    <AccountCircleIcon />
                </Avatar>
                <Typography style={{ marginBottom: 5 }} component="h1" variant="h5">
                    Moj profil
                </Typography>
                <form className={classes.form} noValidate>
                    <Typography style={{ marginBottom: 5 }} component="h6" variant="h5">
                        🤸‍♀️ Ostalo treninga: <strong>{amount < 0 ? <span style={{ color: 'red' }}>{amount}</span> : amount}</strong> <br />
                        {ValidMembership()}
                        {NextValidMembershop()} 
                        <br/>
                        {debt < 0 && <span> 🤸‍♀️ Treninzi van članarine: <strong><span style={{ color: 'red' }}>{debt}</span> </strong> </span>}
                        <br />
                        🎚️ Nivo:  <strong>{userMe?.student?.level}</strong>
                    </Typography>
                    <Box style={{ display: 'flex', justifyContent: "space-evenly", marginTop: 10, marginBottom: 15 }}>
                        <Button

                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => {
                                setListDialog(true)
                            }}
                        >
                            <AssignmentIcon style={{ marginRight: 10 }} />
                            Prisutnost
                        </Button>
                        <Button

                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => {
                                setPaymentDialog(true)
                            }}
                        >
                            <PaymentIcon style={{ marginRight: 10 }} />
                            Plaćanja
                        </Button>
                        {userMe && <ListPayment paymentDialog={paymentDialog} setPaymentDialog={setPaymentDialog} studentID={userMe?.student?.id} />}
                        {userMe && <AttendanceTrainingList listDialog={listDialog} setListDialog={setListDialog} studentID={userMe?.student?.id} />}
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fname"
                                name="firstName"
                                variant="outlined"
                                value={data.firstName}
                                onChange={e => setData({ ...data, firstName: e.target.value })}
                                required
                                fullWidth
                                id="firstName"
                                placeholder="Ime"
                                autoFocus
                                InputProps={{
                                    classes: {
                                        input: classes.input,
                                        notchedOutline: classes.notchedOutline
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                value={data.lastName}
                                onChange={e => setData({ ...data, lastName: e.target.value })}
                                placeholder="Prezime"
                                name="lastName"
                                autoComplete="lname"
                                InputProps={{
                                    classes: {
                                        input: classes.input,
                                        notchedOutline: classes.notchedOutline
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="phone"
                                placeholder="Telefon"
                                value={data.phone}
                                onChange={e => setData({ ...data, phone: e.target.value })}
                                name="phone"
                                autoComplete="phone"
                                InputProps={{
                                    classes: {
                                        input: classes.input,
                                        notchedOutline: classes.notchedOutline
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                id="date-picker-dialog"
                                variant="dialog"
                                inputVariant="outlined"
                                InputAdornmentProps={{ position: "start" }}
                                style={{ width: '100%' }}
                                invalidDateMessage=''
                                format="DD/MM/YYYY"
                                InputProps={{
                                    classes: {
                                        input: classes.input,
                                        notchedOutline: classes.notchedOutline
                                    }
                                }}
                                value={data.birthday}
                                onChange={date => setData({ ...data, birthday: moment(date.toISOString()).format('YYYY-MM-DD') })
                                }
                                KeyboardButtonProps={{
                                    'aria-label': 'Datum rodjenja',
                                }}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                placeholder="Email Address"
                                name="email"
                                value={data.email}
                                onChange={e => setData({ ...data, email: e.target.value })}
                                autoComplete="email"
                                InputProps={{
                                    classes: {
                                        input: classes.input,
                                        notchedOutline: classes.notchedOutline
                                    }
                                }}
                            />
                        </Grid>
                         <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                placeholder="Password"
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                value={data.password}
                                onChange={e => setData({ ...data, password: e.target.value })}
                                autoComplete="current-password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                style={{ color: '#42b6ab ' }}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>)
                                    ,
                                    classes: {
                                        input: classes.input,
                                        notchedOutline: classes.notchedOutline
                                    },

                                }}
                            />
                        </Grid> */}
                        <Grid item xs={12}>

                        </Grid>
                    </Grid>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => {
                            if (data.firstName === '' || data.lastName === '' || data.birthday === '' || data.phone === '') {
                                notify('You must fill in all the fields', 'error')
                            } else {
                                userMe && updateStudent(userMe.student.id, data)
                                    .then(res => {
                                        const students = res
                                        //   const user = res.user
                                        delete students.user
                                        setUserMe({ ...userMe, student: students })
                                        notify('Updated.')

                                    }
                                    )
                                    .catch(error => {
                                        notify(error.response.data.message[0].messages[0].message, 'error')
                                    })
                            }
                        }}
                    >
                        Zapamti
                    </Button>
                    {/*  <Button

                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => {
                            console.log('tetewr')
                        }}
                    >
                        Change password
          </Button> */}
                    {/* <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => {
                            history.push({
                                pathname: '/trainings',
                            })
                        }}
                    >
                        Cancel
          </Button> */}

                </form>
            </div>
        </Container >

    );
}