import * as React from "react";
import { List, Create, Toolbar, SaveButton, SimpleList, Datagrid, Filter, TextField, DateField, NumberField, FormDataConsumer, SimpleForm, TextInput, NumberInput, Edit, ReferenceInput, SelectInput } from 'react-admin';
import DatePicker from '../components/DateTimePicker'
import { useForm } from 'react-final-form';
import Box from '@material-ui/core/Box';
import { useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { DateInput } from '../components/DateInput'

const TitleCosts = ({ record }) => {
    return <span>Prihodi / Rashodi</span>;
};

const CostsFilter = (props) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <Filter {...props}>
            <TextInput source="_q" label="Pretraži" alwaysOn />
            <DateInput source='created_at_gte' label="Od" alwaysOn={isSmall ? false : true} options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />
            <DateInput source='created_at_lte' label="Do" alwaysOn={isSmall ? false : true} options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />
            <SelectInput source="type_contains" choices={[
                { id: 'income', name: 'income' },
                { id: 'cost', name: 'cost' },
            ]} label="Tip" />
        </Filter>
    )
};

export const CostsList = props => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List title={<TitleCosts />} sort={{ field: 'created_at', order: 'DESC' }} bulkActionButtons={false} filters={<CostsFilter />} {...props}>
            {isSmall ?
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => `${record.description ? record.description + ' - ' : ''} ${moment(record.created_at).format('D/MM/YYYY')}`}
                    tertiaryText={record => record.total + ' RSD'}
                /> : <Datagrid rowClick="edit">
                    <TextField source="name" label='Ime' />
                    <TextField source="description" label='Opis' />
                    <TextField source="type" label='Tip' />
                    {/*           <DateField source="startDate" locales="fr-FR" />
                    <DateField source="endDate" locales="fr-FR" />*/}
                    <NumberField source="total" label="Ukupno" />
                    <DateField source="created_at" locales="fr-FR" label="Kreirano" />
                </Datagrid>}
        </List>
    )
};

export const CostsEdit = props => (
    <Edit {...props} undoable={false}>
        <SimpleForm toolbar={<ActionsSave />}>
            <TextInput source="name" label='Ime' />
            <TextInput source="description" label='Opis' multiline />
            <SelectInput source="type" choices={[
                { id: 'income', name: 'income' },
                { id: 'cost', name: 'cost' },
            ]} label="Tip" />
            <NumberInput source="total" label="Ukupno" />
            {/*  <DatePicker source="startDate" type="dateOnly" label="Start date" />
            <DatePicker source="endDate" type="dateOnly" label="End date" />
            <DateInput source="created_at" disabled options={{ format: 'DD/MM/YYYY' }} inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }} /> */}
        </SimpleForm>
    </Edit>
);

const ActionsSave = props => {
    return (
        <Toolbar {...props} >
            <SaveButton disabled={props.pristine ? true : false} />
        </Toolbar>
    )
};

export const CostsCreate = props => {

    return (
        <Create {...props} undoable={false}>
            <SimpleForm redirect="list">
                <TextInput source="name" label="Ime" />
                <TextInput source="description" label="Opis" multiline />
                <SelectInput source="type" choices={[
                    { id: 'income', name: 'income' },
                    { id: 'cost', name: 'cost' },
                ]} label="Tip" defaultValue={'cost'} />
                {/* <DatePicker source="startDate" type="dateOnly" label="Start date" />
                    <DatePicker source="endDate" type="dateOnly" label="End date" /> */}
                <NumberInput source="total" label="Ukupno" defaultValue={0} />
            </SimpleForm>
        </Create>
    )
}