import {List, Datagrid, TextField, DateField } from 'react-admin';

export const LogList = props => (
    <List {...props} bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="message" />
               <DateField source="created_at" />
                  </Datagrid>
    </List>
);