import React from "react";
import Typography from '@material-ui/core/Typography';
import { CheckboxGroupInput } from 'react-admin'
import moment from 'moment'

const ListAppointments = (props) => {

    const [data, setData] = React.useState([])

    React.useEffect(() => {
        props.formData && getAllDates(props.formData)
    }, [])

    React.useEffect(() => {
        props.formData && getAllDates(props.formData)
    }, [props.formData.appointment])



    const getAllDates = (formData) => {

        const dayAppointment = moment(formData.appointment).day()
        const start = moment(formData.appointment),
            end = moment().add(1, 'M'),
            day = dayAppointment;

        let result = [];
        let current = start.clone();

        while (current.day(7 + day).isSameOrBefore(end)) {
            result.push(current.clone());
        }
        const total = result.map(m => {
            return {
                id: m.format('YYYY-MM-DD HH:mmZZ'),
                name: m.format('D/MM/YYYY HH:mm')
            }
        })
        setData([{
            id: moment(formData.appointment).format('YYYY-MM-DD HH:mmZZ'),
            name: moment(formData.appointment).format('D/MM/YYYY HH:mm')
        }, ...total])
    }



    return (<>
        <CheckboxGroupInput source="listappointments" choices={data} label="Izaberi termine:" />

    </>

    );
};

export default ListAppointments;