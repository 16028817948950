/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useInput, FieldTitle } from 'ra-core';
import { KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

const Picker = ({ PickerComponent, ...fieldProps }) => {

    const {
        options,
        label,
        source,
        resource,
        className,
        isRequired,
        inputVariant,
        margin,
        style
    } = fieldProps;

    const { input, meta } = useInput({ source });

    const { touched, error } = meta;

    const handleChange = useCallback(value => {
        Date.parse(value) ? input.onChange(moment(value.toISOString()).format('YYYY-MM-DD')) : input.onChange(null);
    }, []);

    return (
        <div className="picker">

            <PickerComponent
                {...options}
                label={<FieldTitle
                    label={label}
                    source={source}
                    resource={resource}
                    isRequired={isRequired}
                />}
                margin={margin}
                error={!!(touched && error)}
                helperText={touched && error}
                className={className}
                value={input.value ? new Date(input.value) : null}
                onChange={date => handleChange(date)}
                onBlur={() => input.onBlur(input.value ? new Date(input.value).toISOString() : null)}
                inputVariant={inputVariant}
                style={style}
            />


        </div>
    )
}

Picker.propTypes = {
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
    labelTime: PropTypes.string,
    className: PropTypes.string,
    providerOptions: PropTypes.shape({
        utils: PropTypes.func,
        locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
};

Picker.defaultProps = {
    input: {},
    isRequired: false,
    meta: { touched: false, error: false },
    options: {},
    resource: '',
    source: '',
    labelTime: '',
    className: '',
    providerOptions: {
        utils: MomentUtils,
        locale: undefined,
    },
};

export const DateInput = props => <Picker PickerComponent={KeyboardDatePicker} {...props} />
/*export const TimeInput = props => <Picker PickerComponent={KeyboardTimePicker} {...props} />
export const DateTimeInput = props => <Picker PickerComponent={KeyboardDateTimePicker} {...props} />*/