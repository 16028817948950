// in src/MyLayout.js
import * as React from 'react';
import { Layout, Menu, usePermissions } from 'react-admin';
import MyAppBar from './MyAppBar';
import { ClientMenu } from './CustomMenu'

const MyLayout = props => {
    const { permissions } = usePermissions();
    return (<Layout
        {...props}
        appBar={MyAppBar}
        menu={permissions === "Clients" ? ClientMenu : Menu}
    />)
};

export default MyLayout;
