/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { SelectArrayInput, useRefresh, ArrayInput, SimpleFormIterator, SelectInput, NumberInput, useUpdate } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Dialog } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {
    KeyboardDateTimePicker,
} from '@material-ui/pickers';

export const ChangeForm = props => {

    const [changeForm, setChangeForm] = React.useState(false)
    const [selectedDate, handleDateChange] = React.useState(props?.formData?.appointment && props?.formData?.appointment);
    const refresh = useRefresh()
    const data = {
        coaches: props.formData.coaches,
        levels: props.formData.levels,
        studentsPerTraining: props.formData.studentsPerTraining,
        type: props.formData.type,
        duration: props.formData.duration,
        appointment: selectedDate
    }
    const [update] = useUpdate('trainings', props.formData.id, data, props.formData);

    return (<>
        <Button variant="contained" color="primary" style={{ marginLeft: 10 }} onClick={() => setChangeForm(true)}>
            Ispravka
        </Button>
        <Dialog open={changeForm} fullWidth >
            <Box style={{ margin: '10px' }}>
                <ArrayInput source="coaches" label="">
                    <SimpleFormIterator /* addButton={<Button style={{ marginLeft: '20px' }} variant="contained" color="primary" onClick={() => setNoteDialog(false)}>
                    Zatvori
                </Button>} */>
                        <SelectInput source="id" label="Trener" choices={[
                            { id: 111, name: 'Nevena Savic' },
                            { id: 105, name: 'Kristina' },
                            { id: 115, name: 'Sonja' },
                            { id: 120, name: 'Sara' },
                            { id: 143, name: 'Jelena' },
                            { id: 282, name: 'Nevena Deljanin' },
                            { id: 170, name: 'Mia' },
                            { id: 380, name: 'Darija' },
                            { id: 194, name: 'Jana' },
                            { id: 291, name: 'Nevena Rankov' },
                            { id: 280, name: 'Kaca Nikolic' },
                        ]} />
                    </SimpleFormIterator>
                </ArrayInput>
                <Box style={{ display: 'flex', flexDirection: 'column', paddingTop: 25 }}>
                    <KeyboardDateTimePicker value={selectedDate} label="Termin" onChange={handleDateChange} format="DD/MM/yyyy HH:mm" inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }} />
                    <SelectArrayInput label="Nivoi" source="levels" choices={[
                        { id: 'N1', name: 'N1' },
                        { id: 'N2', name: 'N2' },
                        { id: 'N3', name: 'N3' },
                        { id: 'N4', name: 'N4' },
                        { id: 'N5', name: 'N5' },
                        { id: 'Individualni', name: 'Individualni' },
                        { id: 'R', name: 'R' },
                        { id: 'Y', name: 'Y' },
                    ]} />
                    <NumberInput source="studentsPerTraining" label="Max po treningu" />
                    <SelectInput source="type" label="Tip" choices={[
                        { id: 'N1', name: 'N1' },
                        { id: 'N2', name: 'N2' },
                        { id: 'N3', name: 'N3' },
                        { id: 'N4', name: 'N4' },
                        { id: 'N5', name: 'N5' },
                        { id: 'N12', name: 'N12' },
                        { id: 'N23', name: 'N23' },
                        { id: 'N45', name: 'N45' },
                        { id: 'Routine', name: 'Routine' },
                        { id: 'Exotic', name: 'Exotic' },
                        { id: 'Stretch', name: 'Stretch' },
                        { id: 'Individualni', name: 'Individualni' },
                        { id: 'Hoop', name: 'Hoop' },
                        { id: 'SummerBody', name: 'SummerBody' },
                        { id: 'HoopChoreo', name: 'HoopChoreo' },
                        { id: 'PoleArt', name: 'PoleArt' },
                        { id: 'Instructory', name: 'Instructory' },
                        { id: 'Yoga', name: 'Yoga' },
                        { id: 'OpenPole', name: 'OpenPole' }
                    ]} />
                    <SelectInput source="duration" label="Trajanje" initialValue={70} choices={[
                        { id: 70, name: '70 min' },
                        { id: 60, name: '60 min' },
                    ]} />
                </Box>
                <Box display='flex' justifyContent='end' paddingTop='30px'>
                    <Button style={{ marginLeft: '20px' }} variant="contained" color="primary" onClick={() => window.confirm(`Da li si sigurna da želiš da zapamtiš promenu?`) && update() & setChangeForm(false) & refresh()}>
                        Zapamti
                    </Button>

                    <Button style={{ marginLeft: '20px' }} variant="contained" color="primary" onClick={() => setChangeForm(false) & refresh()}>
                        Zatvori
                    </Button>
                </Box>
            </Box>
        </Dialog>
    </>)
};

