import * as React from "react";
import moment from 'moment';
import { useTranslate } from 'react-admin';

export const DateTimeCustom = ({ record = {} }) => {
    const t = useTranslate();
    return (<span>{moment(record.appointment).format('D/MM/YYYY, HH:mm ')} <br /> {t(moment(record.appointment).format('dddd'))}</span>)
};

DateTimeCustom.defaultProps = { label: 'Termin' };




