/* eslint-disable react-hooks/exhaustive-deps */
// in src/MyAppBar.js
import * as React from 'react';
import { AppBar, useRefresh, UserMenu, usePermissions, useNotify } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getUsers, updateUser } from '../Services/Services'
import { toast } from 'react-toastify';
import useWindowFocus from 'use-window-focus';
import { getMe, activateStudent } from '../Services/Services'
import { userContext } from '../context/Context'
import { MyUserMenu } from './MyUserMenu'
import Toolbar from '@material-ui/core/Toolbar';

import { io } from "socket.io-client";
import { AlertTitle } from '@material-ui/lab';
export const socket = io(process.env.REACT_APP_API);

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const MyAppBar = props => {
    const classes = useStyles();
    const userid = localStorage.getItem('userid');
    const windowFocused = useWindowFocus();
    const [userMe, setUserMe] = React.useContext(userContext)
    const { permissions } = usePermissions();
    const notify = useNotify();
    // const refresh = useRefresh();

    React.useEffect(() => {

        socket.on('disconnect', function () {
            socket.emit('join', userid);
        });

        socket.on('notification', (res) => {
            toast.info(res.message, {
                position: "bottom-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: res.id,
                onClose: () => {
                    updateUser(userid, res.message).then(res => res.type === "update" && window.location.reload())
                }
            })
        })

        userid && getMe(userid).then(res => {
            socket.emit('join', userid);
            setUserMe(res)
            res.notifications && res.notifications[0] && res.notifications.forEach(notification => {
                toast.info(notification.message, {
                    position: "bottom-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: notification.message,
                    onClose: () => {
                        updateUser(res.id, notification.message).then(res => notification.type === "update" && window.location.reload())
                    }
                })
            })
        })
        return () => {
            setUserMe()
        }
    }, [])

    /*React.useEffect(() => {
        windowFocused && userMe && getUsers(userMe.id).then(res => {
            res && res[0] && res[0].notifications && res[0].notifications[0] && res[0].notifications.forEach(msg => {
                toast.info(msg, {
                    position: "bottom-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: msg,
                    onClose: () => {
                        updateUser(userMe.id, msg)
                    }
                })
            })

        })

    }, [windowFocused]) */

    return (
        <AppBar userMenu={permissions === 'Clients' ? false : <MyUserMenu />}{...props}>

            {permissions !== 'Clients' && <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />}

            {permissions === 'Clients' && userMe && `Dobro došla ${userMe?.student ? userMe?.student?.firstName : userMe.name}`}
            <span className={classes.spacer} />

        </AppBar>
    );
};

export default MyAppBar;
