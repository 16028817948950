import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useNotify } from 'react-admin';
import { updateTraining } from '../Services/Services'
import { userContext } from '../context/Context'
import moment from 'moment';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function AssignClient(props) {
    const classes = useStyles();
    const notify = useNotify();
    const [userMe] = React.useContext(userContext)
    const [signup, setSignUp] = React.useState(false)
    const [hide, setHide] = React.useState(false)


    const handleClick = () => {

        const studentsAll = props?.record?.students.map(s => s.id)
        const idUser = userMe?.student?.id
        const deleteStudent = studentsAll.filter(x => x !== idUser)
        const res = signup ? deleteStudent : [...studentsAll, idUser]

        props.setLoading(true)
        updateTraining(props.record.id, res)
            .then(res => {
                props.setLoading(false)
                if (typeof res === 'string') notify(res)
            })
    }


    React.useEffect(() => {

        if (userMe && props.record?.students[0]) {
            let arr = props.record?.students.map(s => s.id)
            let foundUser = false
            arr[0] && arr.forEach(id => {
                if (id === userMe.student.id) foundUser = true
            })
            if (foundUser) {
                const today = moment().format('DD/MM/YYYY')
                const checkDate = moment(props.record.appointment).format('DD/MM/YYYY')

                if (checkDate === today) {
                    setHide(true)
                    setSignUp(true)
                }
                else {
                    setSignUp(true)
                    setHide(false)
                }
            }
            else {
                setSignUp(false)
                setHide(false)
            }
        } else {
            setSignUp(false)
            setHide(false)
        }

    }, [props.record.students])

    return (<>
        {hide ? <Button
            disabled={true}
            variant="contained"
            color="primary"
            style={{ backgroundColor: 'green' }}
        >
            Otkaži
        </Button> : <Button
            disabled={props.loading ? true : false}
            variant="contained"
            color="primary"
            style={{ backgroundColor: signup ? 'green' : '#42b6ab' }}
            onClick={handleClick}>
            {signup ? 'Otkaži' : 'Zakaži'}
        </Button>
        }
    </>
    );
}