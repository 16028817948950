/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { SelectArrayInput, ArrayInput, SimpleFormIterator, SelectInput, NumberInput, CheckboxGroupInput } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Dialog } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useForm } from 'react-final-form';
import DateTimePicker from '../components/DateTimePicker'

export const GenerateDialog = props => {

    const form = useForm()
    const [value, setValue] = React.useState('')

    const handleGenerateClick = () => {
        alert(value)
        props.setOpenTopic(false)
        form.change('topic', 'test')
    }


    return (<Dialog open={props.openTopic} fullWidth >
        <Box style={{ margin: '10px' }}>
            <Box display='flex' flexDirection='column'>
                <ArrayInput source="topic">
                    <SimpleFormIterator>
                    <Box display='flex' flexDirection='column'>
                        <DateTimePicker source="appointment" type="DateAndTime" />

                        <SelectInput source="type" value={value} initialValue={"N1"} choices={[
                            { id: 'N1', name: 'N1' },
                            { id: 'N2', name: 'N2' },
                            { id: 'N3', name: 'N3' },
                            { id: 'N4', name: 'N4' },
                            { id: 'N5', name: 'N5' },
                            { id: 'N12', name: 'N12' },
                            { id: 'N23', name: 'N23' },
                            { id: 'N45', name: 'N45' },
                            { id: 'Routine', name: 'Routine' },
                            { id: 'Exotic', name: 'Exotic' },
                            { id: 'Stretch', name: 'Stretch' },
                            { id: 'Individualni', name: 'Individualni' },
                            { id: 'Hoop', name: 'Hoop' },
                            { id: 'SummerBody', name: 'SummerBody' },
                            { id: 'HoopChoreo', name: 'HoopChoreo' },
                            { id: 'PoleArt', name: 'PoleArt' },
                            { id: 'Instructory', name: 'Instructory' },
                            { id: 'Yoga', name: 'Yoga' },
                            { id: 'OpenPole', name: 'OpenPole' }
                        ]} />
                        <SelectInput source="duration" initialValue={70} choices={[
                            { id: 70, name: '70 min' },
                            { id: 60, name: '60 min' },
                        ]} />

                        {/*  <TextInput source="topic" multiline fullWidth /> */}
                        <NumberInput source="studentsPerTraining" initialValue={6} />
                        <SelectArrayInput label="Levels" source="levels" choices={[
                            { id: 'N1', name: 'N1' },
                            { id: 'N2', name: 'N2' },
                            { id: 'N3', name: 'N3' },
                            { id: 'N4', name: 'N4' },
                            { id: 'N5', name: 'N5' },
                            { id: 'R', name: 'R' },
                            { id: 'Y', name: 'Y' },
                        ]} />
                        <CheckboxGroupInput source="repeat" choices={[
                            { id: 'Repeate', name: 'Repeat' },
                          
                        ]} />
                        </Box>
                    </SimpleFormIterator>
                </ArrayInput>

            </Box>
            <Box display='flex' justifyContent='end' paddingTop='30px'>
                <Button style={{ marginLeft: '20px' }} variant="contained" color="primary" onClick={handleGenerateClick}>
                    Generate
                                </Button>

                <Button style={{ marginLeft: '20px' }} variant="contained" color="primary" onClick={() => props.setOpenTopic(false)}>
                    Close
                                </Button>
            </Box>
        </Box>
    </Dialog>
    )
};

