import React, { useState } from "react";
import { useLogin, useNotify } from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Dialog } from '@material-ui/core';
import { forgotPassword } from '../Services/Services'
import SignUp from './SignUpPage'
import ForgotPage from './ForgotPage'
import ResetPassword from './ResetPassword'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#42b6ab',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#42b6ab !important'
    },
    submitDialog: {
        backgroundColor: '#42b6ab !important'
    },
    input: {
        '&::placeholder': {
            color: 'grey',
        },
        'color': 'black'
    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: "#42b6ab !important"
    },
    link: {
        color: "#42b6ab !important"
    }
}));

const MyLoginPage = ({ theme }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false)
    const login = useLogin();
    const notify = useNotify();
    const classes = useStyles();
    const [openForgetDialog, setOpenForgetDialog] = React.useState(false)
    const [openSignUpDialog, setOpenSignUpDialog] = React.useState(false)
    const [resetDialog, setOpenResetDialog] = React.useState(false)
    const [codeParams, setCodeParams] = React.useState()


    const submit = e => {
        e.preventDefault();
        // call the login callback returned by the useLogin hook
        login({ username, password }).catch((error) => alert(error));
    };


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    React.useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get('code')

        if (code) {
            setCodeParams(code)
            setOpenResetDialog(true)
        }
    }, [])

    function Copyright() {
        return (
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://www.poledancehouse.rs/">
                    Pole Dance House
            </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        );
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                   Prijava
        </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        InputProps={{
                            classes: {
                                input: classes.input,
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                        id="email"
                        placeholder="Email"
                        name="email"
                        autoComplete="username"
                        autoFocus
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        style={{ color: '#42b6ab ' }}>
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>)
                            ,
                            classes: {
                                input: classes.input,
                                notchedOutline: classes.notchedOutline
                            },

                        }}
                        name="password"
                        placeholder="Šifra"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}

                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={submit}
                    >
                       Prijavi se
          </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link style={{ cursor: 'pointer' }} onClick={() => setOpenForgetDialog(true)} variant="body2">
                                Zaboravila si šifru?
              </Link>
                        </Grid>
                        <Grid item>
                            <Link style={{ cursor: 'pointer' }} variant="body2" onClick={() => setOpenSignUpDialog(true)}>
                                {"Nemaš nalog? Registruj se"}
                            </Link>
                        </Grid>
                    </Grid>
                    <ForgotPage openForgetDialog={openForgetDialog} setOpenForgetDialog={setOpenForgetDialog} />
                    <SignUp openSignUpDialog={openSignUpDialog} setOpenSignUpDialog={setOpenSignUpDialog} />
                    <ResetPassword setOpenResetDialog={setOpenResetDialog} resetDialog={resetDialog} code={codeParams} />
                </form>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>

    );
};


export default MyLoginPage;