/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
    KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { useForm } from 'react-final-form';
import moment from 'moment';
import { getTraining } from '../Services/Services'

export default function Picker(props) {

    const [selectedDate, handleDateChange] = useState();
    const form = useForm()

    const startdate = () => {

        if (props.source === 'startDate') {
            const date = moment(selectedDate).format('YYYY-MM-DD HH:mm')
            return `&appointment_gte=` + date
        }
        else if (props && props.formData && props.formData.startDate) {
            const date = props.formData.startDate
            return `&appointment_gte=` + date
        }
        else return ''
    }

    const enddate = () => {

        if (props.source === 'endDate') {
            const date = moment(selectedDate).format('YYYY-MM-DD HH:mm')
            return `&appointment_lte=` + date
        }
        else if (props && props.formData && props.formData.endDate) {
            const date = props.formData.endDate
            return `&appointment_lte=` + date
        }
        else return ''
    }
    React.useEffect(() => {


        props && props.type && props.type === 'dateOnly' ?
            form.change(props.source, moment(selectedDate).format('YYYY-MM-DD HH:mm'))
            :
            form.change(props.source, moment(selectedDate).format('YYYY-MM-DD HH:mmZZ'))



        if (props && props.type && props.type === 'dateOnly') {

            getTraining(props.coachID, startdate(), enddate()).then(data => {
                const perTraining = data.length
                /*     const total = data && data.map(training => parseFloat(training.duration))
                     const result = total.reduce((a, b) => a + b, 0)
                     const totalH = (result / 60).toFixed(2) */

                //   form.change('totalHours', totalH)
                const totalTypeTrainings = data && data[0] && data.map(item => item.type)

                function countItems(array) {
                    if (array && array[0]) {
                        let count = {};

                        for (var i = 0; i < array.length; i++) {
                            var item = array[i]

                            if (count[item]) {
                                count[item]++
                            } else {
                                count[item] = 1
                            }
                        }
                        return count
                    }
                }
                
                form.change('totalHours', perTraining)
                form.change('typeOfTrainigs', countItems(totalTypeTrainings))
            })

        }

    }, [selectedDate])

    React.useEffect(() => {

        if (props && props.type && props.type === 'dateOnly') {
            if (props.source === 'startDate') handleDateChange(moment().format("01/MM/yyyy 00:00"))
            if (props.source === 'endDate') handleDateChange(moment().format("01/MM/yyyy 23:59"))
        }

        if (props && props.type && props.type === 'DateAndTime') {
            handleDateChange(moment().format('YYYY-MM-DD HH:mm'))
        }

    }, [])

    React.useEffect(() => {

        getTraining(props.coachID, startdate(), enddate()).then(data => {
            const perTraining = data.length
            /*  const total = data && data.map(training => parseFloat(training.duration))
              const result = total.reduce((a, b) => a + b, 0)
              const totalH = (result / 60).toFixed(2)
              form.change('totalHours', totalH) */
            form.change('totalHours', perTraining)
        })

    }, [props && props.coachID && props.coachID])


    return (<>

        {props && props.type && props.type === 'dateOnly' ?
            <KeyboardDateTimePicker value={selectedDate} label={props.label} onChange={handleDateChange} format="DD/MM/yyyy HH:mm" inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }}
            />
            :
            <KeyboardDateTimePicker value={selectedDate} label={props.label} onChange={handleDateChange} format="DD/MM/yyyy HH:mm" inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }}
            />
        }
    </>

    );
}