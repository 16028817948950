import React, { useState } from "react";
import { useLogin, useNotify } from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Dialog } from '@material-ui/core';
import { forgotPassword } from '../Services/Services'
import SignUp from './SignUpPage'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#42b6ab',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#42b6ab !important'
    },
    submitDialog: {
        backgroundColor: '#42b6ab !important'
    },
    input: {
        '&::placeholder': {
            color: 'grey',
        },
        'color': 'black'
    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: "#42b6ab !important"
    },
    link: {
        color: "#42b6ab !important"
    }
}));

const ForgotPage = (props) => {

    const classes = useStyles();
    const [forgotPass, setForgotPass] = React.useState("")

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper} style={{ backgroundColor: 'white !important' }}>
                <form className={classes.form} noValidate>
                    <Dialog open={props.openForgetDialog} fullWidth>
                        <Box style={{ margin: 10 }}>
                            <Typography variant="body1" color="grey">
                                Poslaćemo ti email sa linkom preko koga možeš da resetuješ šifru. Unesi svoju email adresu:
                            </Typography>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="forgotemail"
                                InputProps={{
                                    classes: {
                                        input: classes.input,
                                        notchedOutline: classes.notchedOutline
                                    }
                                }}
                                type="email"
                                id="emailforgot"
                                value={forgotPass}
                                onChange={e => setForgotPass(e.target.value)}
                            />
                            <Box display='flex' justifyContent='end' style={{ margin: '10px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.submitDialog}
                                    onClick={() => forgotPassword(forgotPass).then(response => {
                                        alert('Email sent.')
                                        props.setOpenForgetDialog(false)
                                    })
                                        .catch(error => {
                                            error && alert(error?.response?.data?.message[0].messages[0].message)
                                        })}>
                                    Pošalji email
                                </Button>
                                <Button
                                    style={{ marginLeft: '20px' }}
                                    variant="contained"
                                    color="primary"
                                    className={classes.submitDialog}
                                    onClick={() => props.setOpenForgetDialog(false)}>
                                    Izadji
                                </Button>
                            </Box>
                        </Box>
                    </Dialog>



                </form>
            </div>

        </Container>

    );
};


export default ForgotPage;