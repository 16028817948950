import * as React from "react";
import Button from '@material-ui/core/Button';
import { Dialog } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TrainingTable from '../TableStudentTraining'
import { getListTrainings } from '../../Services/Services'

export const AttendanceTrainingList = (props) => {

    const [attendance, setAttendance] = React.useState([])



    React.useEffect(() => {

        props.studentID && getListTrainings(props.studentID).then(res => {
            setAttendance([...res].reverse())
        })
    }, [])

    return (
        <>
            <Dialog open={props.listDialog} fullWidth>
                <Box style={{ margin: '10px' }}>
                    <TrainingTable fields={['Termin', 'Tip']}
                        data={attendance && attendance} />
                    <Button style={{ margin: '10px' }} variant="contained" color="primary" onClick={() => props.setListDialog(false)}>
                        Zatvori
    </Button>
                </Box>
            </Dialog>
        </>
    );
}