import * as React from "react";
import authProvider from './providers/authProvider'
import strapiProvider from './providers/strapiProvider'
import { Admin, Resource } from 'react-admin';
import MyTheme from './theme/MyTheme'
//import UserIcon from '@material-ui/icons/Group';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import ReceiptIcon from '@material-ui/icons/Receipt';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
//import ContactsIcon from '@material-ui/icons/Contacts';
//import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PaymentIcon from '@material-ui/icons/Payment';
import TableIcon from '@material-ui/icons/TabletAndroid';
import Notifications from '@material-ui/icons/MessageRounded';
//import CallToActionIcon from '@material-ui/icons/CallToAction';
import { TrainingList, TrainingEdit, TrainingCreate, TrainingListClients } from './Resources/Trainings'
import { StudentsList, StudentEdit, StudentCreate } from './Resources/Students'
//import { UserList, UserEdit } from './Resources/Users'
import { FinanceList, FinanceEdit } from './Resources/Finances'
import { PaymentList, PaymentEdit } from './Resources/Payments'
import { SalaryList, SalaryCreate } from './Resources/Saleries'
import { CostsList, CostsEdit, CostsCreate } from './Resources/Costs'
//import { TopicList, TopicEdit, TopicCreate } from './Resources/Topics'
//import { AgreementList } from './Resources/Agreements'
//import { CampList, CampEdit, CampCreate } from './Resources/CampPayment'
import { fetchJson } from './providers/fetchJson'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyLayout from './Layout/MyLayout'
import customRoutes from './CustomRoutes/CustomRoutes'
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MyContextProvider } from './context/Context'
import LoginPage from './components/LoginPage'
import polyglotI18nProvider from 'ra-i18n-polyglot';
import serbianMessages from './lng/serbian';
import {LogList} from './Resources/Logs'
import {NotificationList,NotificationCreate} from './Resources/Notifications'

const i18nProvider = polyglotI18nProvider(() => serbianMessages, 'hr');

const httpClient = (url, options = {}) => {

  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchJson(url, options);
}

const dataProvider = strapiProvider(process.env.REACT_APP_API, httpClient)
const MyLoginPage = () => <LoginPage />;

const App = () => {

  return (
    <>
      <MyContextProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Admin customRoutes={customRoutes} layout={MyLayout} theme={MyTheme} dataProvider={dataProvider} authProvider={authProvider} loginPage={MyLoginPage} i18nProvider={i18nProvider}>
            {permissions => [
              //Trainings
              permissions === 'Clients' ? <Resource name="trainings" list={TrainingListClients} icon={SportsKabaddiIcon} options={{ label: 'Treninzi' }} />
                : <Resource name="trainings" list={TrainingList} edit={TrainingEdit} create={TrainingCreate} icon={SportsKabaddiIcon} options={{ label: 'Treninzi' }} />,

              // Students
              permissions === 'Clients' ? <Resource name="students" />
                :
                <Resource name="students" list={StudentsList} edit={StudentEdit} create={StudentCreate} icon={SupervisedUserCircleIcon} options={{ label: 'Studenti' }} />,

              //Payments
              permissions === 'SuperAdmin'
                ? <Resource name="payments" list={PaymentList} edit={PaymentEdit} icon={PaymentIcon} options={{ label: 'Plaćanja' }} />
                : permissions === 'Clients' ? <Resource name="payments" /> :
                  <Resource name="payments" list={PaymentList} icon={PaymentIcon} options={{ label: 'Plaćanja' }} />,

              // Camp
              /* permissions === 'SuperAdmin'
               ? <Resource name="camps" list={CampList} edit={CampEdit} create={CampCreate} icon={NaturePeopleIcon} />
               : <Resource name="camps" />, */

              // COSTS

              permissions === 'SuperAdmin'
                ? <Resource name="costs" list={CostsList} edit={CostsEdit} create={CostsCreate} icon={ReceiptIcon} options={{ label: 'Prihodi / rashodi' }} />
                : <Resource name="costs" />,

              // Salaries
              permissions === 'SuperAdmin'
                ? <Resource name="salaries" list={SalaryList} create={SalaryCreate} icon={MoneyOffIcon} options={{ label: 'Plate' }} />
                : <Resource name="salaries" />,

              // Finances
              permissions === 'SuperAdmin'
                ? <Resource name="finances" list={FinanceList} edit={FinanceEdit} icon={MonetizationOnIcon} options={{ label: 'Finansije' }} />
                : <Resource name="finances" />,

                // Logs
                permissions === 'SuperAdmin'
                ? <Resource name="logs" list={LogList} icon={TableIcon} options={{ label: 'Logovi' }} />
                : <Resource name="logs" />,

                 // Notifications
                 permissions === 'SuperAdmin'
                ? <Resource name="notifications" list={NotificationList} icon={Notifications} create={NotificationCreate} options={{ label: 'Notifikacije' }} />
                : <Resource name="notifications" />,

              // TOPIC

              /*  <Resource name="topics" list={TopicList} edit={TopicEdit} create={TopicCreate} icon={CallToActionIcon} />
                ,  */

              // AGREMENTS

              /* permissions === 'SuperAdmin'
                 ?
                 <Resource name="agrements" list={AgreementList} icon={ContactsIcon} />
                 : <Resource name="agrements" />, */

              // USERS
              permissions === 'SuperAdmin'
                ?
                <Resource name="users" /* list={UserList} edit={UserEdit} icon={UserIcon} */ />
                : <Resource name="users" />,
            ]}

          </Admin>
          <ToastContainer />
        </MuiPickersUtilsProvider>
      </MyContextProvider>
    </>
  )
};

export default App;

