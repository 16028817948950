import * as React from "react";

export const AssignedToTraining = ({ record = {} }) => {

    const number = record?.students?.length

    return (<span>{number}</span>)
};

AssignedToTraining.defaultProps = { label: 'Assiged for training' };




