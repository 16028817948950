import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useRefresh, useUpdate, useNotify } from 'react-admin';
import Box from '@material-ui/core/Box';
import { updateTraining } from '../Services/Services'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function CheckboxList(props) {
    const classes = useStyles();
    const [checked, setChecked] = React.useState([]);
    const notify = useNotify();
    const dataArray = () => {
        // studentsLocation svi studenti za zadatu lokaciju
        // pros.training.students svi studenti koji su upisani na zadatu lokaciju
        const studentsLocation = Object.values(props.data && props.data)
        const filteredElements = studentsLocation.filter(function (obj) {
            return !props.training.students.some(function (obj2) {
                return obj.id === obj2.id;
            });
        });
        filteredElements.sort((a, b) => a.firstName.localeCompare(b.firstName))
        return filteredElements
    }
    const allStudents = props.training && props.training.students ? [...props.training && props.training.students, ...checked] : checked
    const dataStudents = {
        students: allStudents
    }
    const [update] = useUpdate('trainings', props.training.id, dataStudents, props.training);
    const refresh = useRefresh()

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (<>   <Box style={{ margin: '10px' }}>
        <List className={classes.root}>
            {dataArray() && dataArray().map((value) => {
                const labelId = `checkbox-list-label-${value.id}`;
                return (
                    <ListItem key={value.id} role={undefined} dense button onClick={handleToggle(value.id)}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={checked.indexOf(value.id) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value.firstName + ' ' + value.lastName}`} />
                    </ListItem>
                );
            })}
        </List>
        <Box display='flex' justifyContent='end' paddingTop='30px'>
            <Button variant="contained" color="primary" onClick={() => {

                if (!checked[0]) return notify('Please select at least one student.', 'warning')
                //  update() 

                updateTraining(props.training.id, dataStudents.students)
                    .then(res => {
                        if (typeof res === 'string') notify(res)
                       
                    })

                props.setOpenDialog(false)
            }}>
                Ubaci
                                </Button>
            <Button style={{ marginLeft: '20px' }} variant="contained" color="primary" onClick={() => props.setOpenDialog(false)}>
                Otkaži
                                </Button>
        </Box>
    </Box>
    </>
    );
}