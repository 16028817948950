/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { List, Datagrid, SimpleList, Toolbar, SaveButton, TextField, DateField, NumberField, SimpleForm, TextInput, NumberInput, Edit, Filter } from 'react-admin';
import moment from 'moment';
import { useMediaQuery } from '@material-ui/core';
import { getFinances } from '../Services/Services'
import { DateInput } from '../components/DateInput'

const TitleFinance = ({ record }) => {
    return <span>Finansije</span>;
};

const Aside = (props) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const [totalIncomes, setTotalIncomes] = React.useState(0)
    const [totalCosts, setTotalCosts] = React.useState(0)

    React.useEffect(() => {
        getFinances(props.filterValues).then(data => {
            const incomes = data && data.map(finance => finance.type === 'income' ? finance.amount : 0)
            const costs = data && data.map(finance => finance.type === 'cost' ? finance.amount : 0)
            setTotalIncomes(incomes.reduce((a, b) => a + b, 0))
            setTotalCosts(costs.reduce((a, b) => a + b, 0))
        })
    }, [props.filterValues])

    return (<> {isSmall ? <div style={{ width: 130, margin: '1em' }} >
        Prihod: <br /> {totalIncomes} RSD <br />
        Rashod: <br /> {totalCosts} RSD <br />
        Zarada: <br /> {totalIncomes - totalCosts} RSD</div>
        :
        <div style={{ width: 200, margin: '1em' }}>
            Prihod: {totalIncomes.toFixed(2)} RSD <br />
            Rashod: {totalCosts.toFixed(2)} RSD <br />
            Zarada: {(totalIncomes - totalCosts).toFixed(2)} RSD </div>} </>
    )
};

const FinanceFilter = (props) => {
    return (
        <Filter {...props}>
            <TextInput source="_q" label="Pretraži" alwaysOn />
            <DateInput source='created_at_gte' label="Od" alwaysOn options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />
            <DateInput source='created_at_lte' label="Do" alwaysOn options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />
        </Filter>
    )
};

export const FinanceList = props => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List title={<TitleFinance />} aside={<Aside />} sort={{ field: 'created_at', order: 'DESC' }} filters={<FinanceFilter />} filterDefaultValues={{ created_at_gte: moment().format("YYYY-MM-01") }} bulkActionButtons={false} {...props}>
            {isSmall ?
                <SimpleList
                    primaryText={record => record.type === 'cost' ? record.desciption + ' ' + record.amount + ' RSD' : record.desciption + ' RSD'}
                    secondaryText={record => moment(record.created_at).format('DD/MM/YYYY') + ' - ' + record.type}
                />
                : <Datagrid rowClick="edit">
                    <TextField source="desciption" label="Opis" />
                    <TextField source="type" label="Tip" />
                    <NumberField source="amount" label="Iznos" />
                    <DateField source="created_at" locales="fr-FR" label="Kreirano" />
                </Datagrid>}
        </List>
    )
};

export const FinanceEdit = props => (
    <Edit {...props} undoable={false}>
        <SimpleForm toolbar={<ActionsSave />}>
            <TextInput source="desciption" label="Opis" />
            <NumberInput source="amount" />
        </SimpleForm>
    </Edit>
);


const ActionsSave = props => {
    return (
        <Toolbar {...props} >
            <SaveButton disabled={props.pristine ? true : false} />
        </Toolbar>
    )
};