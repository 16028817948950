import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#42b6ab',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(1, 0, 1),
        backgroundColor: '#42b6ab !important'
    },
    submitDialog: {
        backgroundColor: '#42b6ab !important'
    },
    input: {
        '&::placeholder': {
            color: 'grey',
        },
        'color': 'white'
    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: "#42b6ab !important"
    },
    link: {
        color: "#42b6ab !important"
    },
    formControl: {

    },

}));

export default function Prices() {
    const classes = useStyles();
    return (

        <Container component="main" maxWidth="xs">
            <div className={classes.paper} tyle={{ backgroundColor: 'white !important' }}>
                <Avatar className={classes.avatar}>
                    <AttachMoneyIcon />
                </Avatar>
                <Typography style={{ marginBottom: 30 }} component="h1" variant="h5">
                    Cenovnik
                </Typography>
                <Grid container spacing={2}>
                    <Typography variant="body2" style={{ marginBottom: 10 }}>
                        <strong>Cene paketa od 5.2.2024:</strong>
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: 5 }}>
                        <strong>Pettit</strong> (4 termina) – 4600 din 🤏<br />
                        <strong>8-pack</strong> (8 termina) – 7000 din 💪<br />
                        <strong>Dirty dozen</strong> (12 termina) – 8400 din 🦸‍♀️<br />
                        <strong>Pojedinačan trening</strong> (u grupi) - 1700 din 🤸<br />
                        <strong>Individualni trening</strong> (1 na 1 sa instruktorkom) – 3000 din 💃🏻<br />
                        <strong>Poluindividualni trening</strong> (2 osobe na treningu) – 5000 din 👭<br />
                        <strong>All yours</strong> (8 individualnih termina) – 20000 din 🔥<br />
                        <br />
                    </Typography>
                    <Grid item xs={12}>
                    </Grid>
                </Grid>
            </div>
        </Container>

    );
}