

const serbianMessages = {
    ra: {
        action: {
            delete: 'Ukloni',
            show: 'Prikaži',
            list: 'Lista',
            save: 'Sačuvaj',
            create: 'Napravi',
            edit: 'Uredi',
            cancel: 'Otkaži',
            refresh: 'Osveži',
            add_filter: 'Dodajte filter',
            remove_filter: 'Ukloni filter',
            back: 'Nazad',
            export: 'Izvezi',
            add: 'Dodaj',
            remove: 'Obriši',
            bulk_actions:
                '%{smart_count} selektovano',
        },
        boolean: {
            true: 'Da',
            false: 'Ne',
        },
        page: {
            list: 'Lista %{name}',
            edit: '%{name} #%{id}',
            show: '%{name} #%{id}',
            create: 'Napravi %{name}',
            delete: 'Ukloni %{name} #%{id}',
            dashboard: 'Kontrolna tabla',
            not_found: 'Nije pronađeno',
            empty: 'Još uvek nema podataka',
            invite: 'Možete napraviti novi podatak ukoliko kliknete na dugme ispod',
            error: 'Greška',
        },
        input: {
            file: {
                upload_several:
                    'Ispustite neke datoteke koje želite preuzeti ili kliknite da biste ih odabrali.',
                upload_single: 'Ispustite datoteku koju želite preuzeti ili kliknite je da biste je odabrali.',
            },
            image: {
                upload_several:
                    'Ispustite neke slike za prijenos ili kliknite da biste ih odabrali.',
                upload_single:
                    'Ispustite sliku koju želite prenijeti ili kliknite je da biste je odabrali.',
            },
        },
        message: {
            yes: 'Da',
            no: 'Ne',
            are_you_sure: 'Jeste li sigurni?',
            about: 'Oko',
            not_found:
                'Jeste li uneli pogrešan URL ili ste pratili pogrešnu vezu?',
            error: 'Došlo je do greške',
            details: 'Detalji'
        },
        navigation: {
            no_results: 'Nema rezultata',
            page_out_of_boundaries: 'Broj stranice %{page} je izvan granice',
            page_out_from_end: 'Ne može se premašiti zadnja stranica',
            page_out_from_begin: 'Ne mogu preći stranicu 1',
            page_range_info: '%{offsetBegin}-%{offsetEnd} od ukupno %{total}',
            next: 'Napred',
            prev: 'Nazad',
            page_rows_per_page: 'Po strani',
        },
        auth: {
            username: 'Korisničko ime',
            password: 'Lozinka',
            sign_in: 'Prijavi se',
            sign_in_error: 'Autentifikacija nije uspela, pokušajte ponovo',
            logout: 'Odjava',
            user_menu: 'Meni',
        },
        notification: {
            updated: 'Element je ažuriran',
            created: 'Element je izrađen',
            deleted: 'Element je uklonjen',
            item_doesnt_exist: 'Element ne postoji',
            http_error: 'Greškaa pri komunikaciji sa serverom',
        },
        validation: {
            required: 'Potreban',
            minLength: 'Mora sadržati najmanje %{min} znakova',
            maxLength: 'Mora sadržati najviše %{max} znakova ili manje',
            minValue: 'Mora biti barem %{min}',
            maxValue: 'Mora biti %{max} ili manje',
            number: 'Mora biti broj',
            email: 'Mora biti važeća e-adresa',
        },
    },
    'Monday': 'Ponedeljak',
    'Tuesday': 'Utorak',
    'Wednesday': 'Sreda',
    'Thursday': 'Četvrtak',
    'Friday': 'Petak',
    'Saturday': 'Subota',
    'Sunday': 'Nedelja',

};

export default serbianMessages;

