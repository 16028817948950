/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useRefresh, useUpdate, Toolbar, useGetOne, CreateButton, CardActions, useGetList, useCreate, usePermissions } from 'react-admin';
import Button from '@material-ui/core/Button';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import TextField from '@material-ui/core/TextField';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { useHistory } from "react-router-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import ListAddStudents from '../components/ListAddStudents'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import {
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import ListPayments from './ListPayments'
import TrainingTable from './TableStudentTraining'
import { toast } from 'react-toastify';
import { userContext } from '../context/Context'
import { getMe, studentFiltersLevels } from '../Services/Services'

export const ActionsList = props => (
    <CardActions>
        <CreateButton basePath={'/trainings'} />
    </CardActions>
);

export const ActionsSave = props => (
    <Toolbar {...props} >
        { /* <SaveButton disabled={props.pristine ? true : false} /> */}
        <CardActions />
    </Toolbar>
);

export const AddStudent = (props) => {

    const [openDialog, setOpenDialog] = React.useState()
    const [data, setData] = React.useState()

    React.useEffect(() => {
        studentFiltersLevels(props.formData.levels).then(res => setData(res))
    }, [])

    /*const { data } = useGetList(
        'students',
        { page: 1, perPage: 1000 },
        { field: 'id', order: 'DESC' },
        { active: true }
    ); */

    return (<>
        <Dialog open={openDialog} fullWidth>

            {data && <ListAddStudents data={data} setOpenDialog={setOpenDialog} training={props.formData} />}

        </Dialog>
        <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)
        }>
            Dodaj
        </Button>
    </>)

}


export const AssignButton = (props) => {

    const [userMe] = React.useContext(userContext)
    const userid = localStorage.getItem('userid');

    React.useEffect(() => {

        userid && getMe(userid).then(res => {
            if (props && props.record && props.record.coaches) {
                props.record.coaches.forEach(coach => {
                    if (coach.id === res.id) {
                        return props.setIsLogged(true)
                    }
                })

            }

        })




    }, [])

    const refresh = useRefresh();
    const data = { coaches: [...props && props.record && props.record.coaches && props.record.coaches, userMe && { id: userMe.id, username: userMe.username }] }
    const [update] = useUpdate('trainings', props && props.record && props.record.id && props.record.id, data, props && props.record && props.record);

    return (<>
        <Button variant="contained" color="primary" disabled={props.isLogged ? true : false} onClick={() => {
            if (props.isLogged !== true) {
                update()
                // refresh()
                props.setIsLogged(true)
            }
        }}>
            Prijavi me
        </Button>
    </>)
}

export const SaveButtonNotes = (props) => {

    const data = {
        Notes: props.formData.Notes
    }

    const [update] = useUpdate('trainings', props.formData.id, data, props.formData);

    return <Button variant="contained" color="primary"
        onClick={() => props.setNoteDialog(false) & update()}
    >
        Zapamti
    </Button>
}

const useStyles = makeStyles({
    notification: {
        color: 'red'
    }
});

export const ProfileButton = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    let history = useHistory();
    const classes = useStyles();
    const [studentNoteDialog, setStudentNoteDialog] = React.useState(false)
    const { data } = useGetOne('students', props.student.id);
    const [value, setValue] = React.useState(null)
    const newNote = {
        note: value
    }
    const [update] = useUpdate('students', props.student.id, newNote, props.student);
    const refresh = useRefresh()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        data && setValue(data.note)
    }, [data])

    return (
        <>
            <IconButton edge="end" aria-label="delete" onClick={handleClick}>
                {props.student.note ? <Badge badgeContent={'N'} color="error">
                    <PermContactCalendarIcon />
                </Badge>
                    : <PermContactCalendarIcon />
                }
            </IconButton>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => history.push(`/students/${props.student.id}`)}>Profil</MenuItem>
                <MenuItem className={props.student.note ? classes.notification : null} onClick={() => setStudentNoteDialog(true) & handleClose()} >Beleške</MenuItem>
            </Menu>
            <Dialog open={studentNoteDialog} fullWidth>

                <TextField style={{ margin: '10px' }} onChange={(e => setValue(e.target.value))} value={value} id="filled-basic" label="Student note" variant="filled" multiline />
                <Box display='flex' justifyContent='end' style={{ margin: '10px' }}>
                    <Button variant="contained" color="primary" onClick={() => update() & refresh()}>
                        Save
                    </Button>
                    <Button style={{ marginLeft: '20px' }} variant="contained" color="primary" onClick={() => setStudentNoteDialog(false)}>
                        Close
                    </Button>
                </Box>
            </Dialog>
        </>
    );
}

export const DeleteStudentButton = (props) => {

    const deleteStudent = props.training && props.training.students && props.training.students.map(x => x && x.id !== props.student.id && x)

    const data = {
        students: deleteStudent
    }

    const [update] = useUpdate('trainings', props.training && props.training.id, data, props.training && props.training);

    return (<IconButton edge="end" aria-label="delete" onClick={() => {
        if (window.confirm(`Obriši ${props.student.firstName} ${props.student.lastName} sa treninga?`)) update()
    }}>
        <DeleteIcon />
    </IconButton>)
}

export const PayButton = (props) => {
    const options = ['4600', '7000', '8400', '20000', '500', '1700', '3000', '5000'];
    const [studentPayDialog, setStudentPayDialog] = React.useState(false)
    const [autoCompleteinputValue, setAutoCompleteInputValue] = React.useState('');
    const [startDate, handleStartDateChange] = React.useState(moment().format('YYYY-MM-DD'));
    const [endDate, handleEndDateChange] = React.useState();
    const [values, setValues] = React.useState({
        amount: '',
        fiscalNumber: null,
        amountTraining: 8,
        //    year: '2020',
        //   month: '12',
    })
    const [autoCV, setAutoCV] = React.useState('4600')
    const [userMe] = React.useContext(userContext)

    React.useEffect(() => {
        if (autoCompleteinputValue === '500' || autoCompleteinputValue === '1700' || autoCompleteinputValue === '3000' || autoCompleteinputValue === '5000') {
            return handleEndDateChange(startDate)
        }
        handleEndDateChange(moment(startDate).add(1, 'months').format("YYYY-MM-DD"))
    }, [startDate])

    React.useEffect(() => {
        studentPayDialog && props.student.note && toast.info('Note: ' + props.student.note, {
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: props.student.id
        })
    }, [studentPayDialog])

    React.useEffect(() => {
        switch (autoCompleteinputValue) {
            case '4600':
                setValues(x => ({ ...x, amountTraining: 4 }))
                break;
            case '7000':
                setValues(x => ({ ...x, amountTraining: 8 }))
                break;
            case '8400':
                setValues(x => ({ ...x, amountTraining: 12 }))
                break;
            case '20000':
                setValues(x => ({ ...x, amountTraining: 8 }))
                break;
            case '500':
                setValues(x => ({ ...x, amountTraining: 1 }))
                break;
            case '1700':
                setValues(x => ({ ...x, amountTraining: 1 }))
                break;
            case '3000':
                setValues(x => ({ ...x, amountTraining: 1 }))
                break;
            case '5000':
                setValues(x => ({ ...x, amountTraining: 1 }))
                break;
            default:
                setValues(x => ({ ...x, amountTraining: 1 }))
                break;
        }
    }, [autoCompleteinputValue])

    const dataPayment = {
        amount: autoCompleteinputValue,
        // month: values && parseInt(values.month), 
        // year: values && parseInt(values.year),
        amountTraining: values.amountTraining,
        fiscalNumber: values.fiscalNumber,
        description: values && `${props.student.firstName + ' ' + props.student.lastName} je platio ${autoCompleteinputValue}`,
        student: props.student.id,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        coach: userMe && userMe.id
    }

    const [createPayment] = useCreate('payments', dataPayment);

    const refresh = useRefresh();
    const { permissions } = usePermissions();

    return (<>
        {props.button === true ? <Button style={{ marginBottom: '30px' }} variant="contained" color="primary" onClick={() => setStudentPayDialog(true)} >
            Plaćanja
        </Button> : <IconButton edge="end" aria-label="delete" onClick={() => setStudentPayDialog(true)}>
            <MonetizationOnIcon />
        </IconButton>}
        <Dialog open={studentPayDialog} fullWidth>
            <Box style={{ margin: '10px' }}>
                <Box style={{ display: 'flex' }}>
                    <Autocomplete
                        id="controllable-states-demo"
                        inputValue={autoCompleteinputValue}
                        value={autoCV}
                        onChange={(event, newValue) => {
                            setAutoCV(newValue)
                        }}
                        onInputChange={(event, newInputValue) => {
                            if (newInputValue === '500' || newInputValue === '1700' || newInputValue === '3000' || newInputValue === '5000') {
                                handleStartDateChange(moment().format("YYYY-MM-DD"))
                                handleEndDateChange(moment().format("YYYY-MM-DD"))
                            } else handleEndDateChange(moment(startDate).add(1, 'months').format("YYYY-MM-DD"))
                            setAutoCompleteInputValue(newInputValue);
                        }}
                        freeSolo={true}
                        options={options}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Iznos" variant="filled" />}
                    />
                    <TextField fullWidth style={{ paddingLeft: 10 }} onChange={e => setValues(x => ({ ...x, amountTraining: e.target.value }))} value={values.amountTraining} id="filled-basic" label="Broj treninga" variant="filled" />
                    {/*permissions === 'SuperAdmin' && <TextField fullWidth style={{ paddingLeft: 10 }} onChange={e => setValues(x => ({ ...x, fiscalNumber: e.target.value }))} value={values.month} id="filled-basic" label="Fiscal number" variant="filled" /> */}
                </Box>
                <Box display='flex' flexDirection='column'>
                    <KeyboardDatePicker style={{ marginTop: '10px' }} format="DD/MM/yyyy" value={startDate} label="Važi od" onChange={handleStartDateChange} inputVariant="filled" margin="dense" />
                    <KeyboardDatePicker style={{ marginTop: '10px' }} format="DD/MM/yyyy" value={endDate} label="Važi do" onChange={handleEndDateChange} inputVariant="filled" margin="dense" />
                </Box>


                {/*<TextField onChange={e => setValues(x => ({ ...x, year: e.target.value }))} value={values.year} id="filled-basic" label="year" variant="filled" />*/}

                <Box display='flex' justifyContent='end' paddingTop='20px' paddingBottom='10px'>
                    <Button variant="contained" color="primary" onClick={() => createPayment() & refresh()}>
                        Naplati
                    </Button>

                    <Button style={{ marginLeft: '20px' }} variant="contained" color="primary" onClick={() => setStudentPayDialog(false)}>
                        Zatvori
                    </Button>
                </Box>
                <ListPayments studentsID={props.student.id} />

                {/* <SimpleList
                primaryText={record => studentPayment.amount + ' din'}
                secondaryText={record => moment(studentPayment.startDate).format('D/MM/YYYY') + ' - ' + moment(studentPayment.endDate).format('D/MM/YYYY')}
            />*/}
            </Box>
        </Dialog>
    </>)
}

export const NoteStudentDialog = (props) => {

    const [studentNoteDialog, setStudentNoteDialog] = React.useState(false)
    const { data } = useGetOne('students', props.student.id);
    const [value, setValue] = React.useState(null)
    const newNote = {
        note: value
    }
    const [update] = useUpdate('students', props.student.id, newNote, props.student);

    React.useEffect(() => {
        data && setValue(data.note)
    }, [data])

    return (
        <>
            <Button variant="contained" color="primary" onClick={() => setStudentNoteDialog(true)}>
                Change note
            </Button>

            <Dialog open={studentNoteDialog} fullWidth>
                <Box style={{ margin: '10px' }}>
                    <TextField onChange={(e => setValue(e.target.value))} value={value} id="filled-basic" label="Student note" variant="filled" multiline fullWidth />
                    <Box display='flex' justifyContent='end' paddingTop='30px'>
                        <Button variant="contained" color="primary" onClick={() => update()}>
                            Save
                        </Button>
                        <Button style={{ marginLeft: '20px' }} variant="contained" color="primary" onClick={() => setStudentNoteDialog(false)}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Dialog>

        </>
    );
}


export const StudentTrainingList = (props) => {

    const [studentTrainingDialog, setStudentTrainingDialog] = React.useState(false)
    const attendance = props.trainings ? [...props.trainings].reverse().sort((a, b) => new Date(b.appointment) - new Date(a.appointment)) : []

    return (
        <>
            <Button style={{ marginBottom: '30px' }} variant="contained" color="primary" onClick={() => setStudentTrainingDialog(true)}>
                Prisutnost
            </Button>
            <Dialog open={studentTrainingDialog} fullWidth>
                <Box style={{ margin: '10px' }}>
                    <TrainingTable fields={['Termin', 'Tip']}
                        data={attendance} />
                    <Button style={{ margin: '10px' }} variant="contained" color="primary" onClick={() => setStudentTrainingDialog(false)}>
                        Zatvori
                    </Button>
                </Box>
            </Dialog>
        </>
    );
}