import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Dialog } from '@material-ui/core';
import { resetPassword } from '../Services/Services'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#42b6ab',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#42b6ab !important'
    },
    submitDialog: {
        backgroundColor: '#42b6ab !important'
    },
    input: {
        '&::placeholder': {
            color: 'grey',
        },
        'color': 'black'
    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: "#42b6ab !important"
    },
    link: {
        color: "#42b6ab !important"
    }
}));

const ResetPassword = (props) => {

    const classes = useStyles();
    const [data, setData] = React.useState({
        confirmPassword: '',
        password: ''
    })
    const [showPassword, setShowPassword] = React.useState(false)
    const [showPassword1, setShowPassword1] = React.useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowPassword1 = () => {
        setShowPassword1(!showPassword1);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper} style={{ backgroundColor: 'white !important' }}>
                <form className={classes.form} noValidate>
                    <Dialog open={props.resetDialog} fullWidth>
                        <Box style={{ margin: 10 }}>
                            <Typography variant="body1" color="grey">
                                Unesite vašu novu šifru:
                    </Typography>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="pass"
                                placeholder="Šifra"
                                type={showPassword ? 'text' : 'password'}
                                id="pass"
                                value={data.password}
                                onChange={e => setData({ ...data, password: e.target.value })}
                                autoComplete="current-password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                style={{ color: '#42b6ab ' }}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>)
                                    ,
                                    classes: {
                                        input: classes.input,
                                        notchedOutline: classes.notchedOutline
                                    },

                                }}
                            />
                            <Typography variant="body1" color="grey">
                                Unesite ponovo vašu novu šifru:
                    </Typography>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="confirmpassword"
                                placeholder="Potvrda nove šifre"
                                type={showPassword1 ? 'text' : 'password'}
                                id="confirmpassword"
                                value={data.confirmPassword}
                                onChange={e => setData({ ...data, confirmPassword: e.target.value })}
                                autoComplete="current-password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword1}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                style={{ color: '#42b6ab ' }}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>)
                                    ,
                                    classes: {
                                        input: classes.input,
                                        notchedOutline: classes.notchedOutline
                                    },

                                }}
                            />
                            <Box display='flex' justifyContent='end' style={{ margin: '10px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.submitDialog}
                                    onClick={() => {

                                        if (data.password !== data.confirmPassword) return alert('Oba polja moraju biti popunjena.')
                                        console.log(props.code)
                                        resetPassword(data.password, data.confirmPassword, props.code).then(response => {
                                            alert('Šifra je uspešno promenjena. Možete se prijaviti u aplikaciju.')
                                            window.location.replace(`https://app.poledancehouse.rs/login`);
                                        })
                                            .catch(error => {
                                                error && alert(error?.response?.data?.message[0].messages[0].message)
                                            })
                                    }}>
                                    Promeni šifru
        </Button>
                                <Button
                                    style={{ marginLeft: '20px' }}
                                    variant="contained"
                                    color="primary"
                                    className={classes.submitDialog}
                                    onClick={() => props.setOpenResetDialog(false)}>
                                    Izadji
    </Button>
                            </Box>
                        </Box>
                    </Dialog>



                </form>
            </div>

        </Container>

    );
};


export default ResetPassword;