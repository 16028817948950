import * as React from "react";
import { List, useListContext, useTranslate, CheckboxGroupInput, TopToolbar, sanitizeListRestProps, NumberInput, ReferenceArrayInput, Create, SelectArrayInput, Filter, Datagrid, SimpleList, TextField, NumberField, ArrayField, SingleFieldList, ChipField, FormDataConsumer, SimpleForm, TextInput, Edit, ReferenceInput, ArrayInput, SimpleFormIterator, SelectInput, useRefresh } from 'react-admin';
import moment from 'moment';
import { useMediaQuery } from '@material-ui/core';
import { DateTimeCustom } from '../components/DateTimeField'
import PersonIcon from '@material-ui/icons/Person';
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button';
import { Dialog } from '@material-ui/core';
import { ActionsList, AssignButton, SaveButtonNotes, AddStudent } from '../components/Buttons'
import ItemsTable from '../components/TableListStudents'
import DateTimePicker from '../components/DateTimePicker'
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import { GenerateDialog } from '../components/GenerateTopicDialog'
import { userContext } from '../context/Context'
import HomeIcon from '@material-ui/icons/Home';
import { AssignedToTraining } from '../components/AssignedToTraining'
import ListAppointments from '../components/ListRepeatedAppointments'
import { DateInput } from '../components/DateInput'
import useWindowFocus from 'use-window-focus';
import Typography from '@material-ui/core/Typography';
import AssignClient from '../components/AssignClient'
import { ChangeForm } from '../components/ChangeForm'
import { socket } from '../Layout/MyAppBar'
// const socket = io(process.env.REACT_APP_API);


const Title = ({ record }) => {
    return <span>{record.type}</span>;
};

const TitleTraining = ({ record }) => {
    return <span>Treninzi</span>;
};

const TrainingFilter = (props) => (
    <Filter {...props}>
        <TextInput source="_q" label="Pretraži" alwaysOn />
        <DateInput source='appointment_gte' label="Od" options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />
        <DateInput source='appointment_lte' label="Do" options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />

        <SelectInput source="coaches" label="Trener" choices={[
            { name: 'Nevena Savic', id: 111 },
            { name: 'Kristina', id: 105 },
            { name: 'Sonja', id: 115 },
            { name: 'Sara', id: 120 },
            { id: 143, name: 'Jelena' },
            { id: 282, name: 'Nevena Deljanin' },
            { id: 170, name: 'Mia' },
            { id: 380, name: 'Darija' },
            { id: 194, name: 'Jana' },
            { id: 291, name: 'Nevena Rankov' },
            { id: 280, name: 'Kaca Nikolic' },
        ]} />
        <SelectInput source="type" label="Tip" choices={[
            { id: 'N1', name: 'N1' },
            { id: 'N2', name: 'N2' },
            { id: 'N3', name: 'N3' },
            { id: 'N4', name: 'N4' },
            { id: 'N5', name: 'N5' },
            { id: 'N12', name: 'N12' },
            { id: 'N23', name: 'N23' },
            { id: 'N45', name: 'N45' },
            { id: 'Routine', name: 'Routine' },
            { id: 'Exotic', name: 'Exotic' },
            { id: 'Stretch', name: 'Stretch' },
            { id: 'Individualni', name: 'Individualni' },
            { id: 'Hoop', name: 'Hoop' },
            { id: 'SummerBody', name: 'SummerBody' },
            { id: 'HoopChoreo', name: 'HoopChoreo' },
            { id: 'PoleArt', name: 'PoleArt' },
            { id: 'Instructory', name: 'Instructory' },
            { id: 'Yoga', name: 'Yoga' },
            { id: 'OpenPole', name: 'OpenPole' }
        ]} />
    </Filter>
);

const ListActions = (props) => {

    const {
        className,
        filters,
        maxResults,
        permissions,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
        total,
    } = useListContext();

    const [userMe, setUserMe] = React.useContext(userContext)
    const [amount, setAmoutT] = React.useState()
    const [debt, setDebt] = React.useState()
    const [validDate, setValidDate] = React.useState()
    const [payed, setPayed] = React.useState()

    const setAmountDebtTrainigns = (amount, debt) => {
        setAmoutT(
            amount
        )
        setDebt(debt)
    }

    React.useEffect(() => {
        if (userMe) {
            setAmountDebtTrainigns(userMe.student.amountTraining, userMe.student.debtTraining)
            setValidDate(userMe?.validUntil?.endDate)
            setPayed(userMe?.payed)
        }

        socket.on('amounttraining', (res) => {
            setUserMe(res)
        })
        //    return () => socket.off('amounttraining');
    }, [])

    React.useEffect(() => {
        if (userMe) {
            setAmountDebtTrainigns(userMe.student.amountTraining, userMe.student.debtTraining)
            setValidDate(userMe?.validUntil?.endDate)
            setPayed(userMe?.payed)
        }

    }, [userMe])

    const ValidMembership = () => {
        if (!validDate) {
            return (<span style={{ color: 'red' }}>{`🎫 Nije uplaćena članarina.`}</span>)
        }

        if (validDate) {

            if (amount <= 0) {
                return (<span style={{ color: 'red' }}>{`🎫 Trenutna članarina je iskorišćena.`}</span>)
            }

            return (`🎫 Važi do: ${moment(validDate).format('DD/MM/YYYY')}`)

        }
    }

    const NextValidMembershop = () => {
        if (payed === false) {
            return (<span> <br /><span style={{ color: 'red' }}>{`🎫 Plaćanje članarine na narednom treningu.`}</span>
            </span>)
        }
    }

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}

            <Typography variant="body2" color="textSecondary" >
                🤸‍♀️ Ostalo treninga: <strong>{amount <= 0 ? <span style={{ color: 'red' }}>{amount}</span> : amount}</strong> <br />
                {ValidMembership()}
                {NextValidMembershop()}
                <br />
                {debt < 0 && <span> 🤸‍♀️ Zakazani treninzi van članarine: <strong><span style={{ color: 'red' }}>{debt}</span> </strong> </span>}
            </Typography>

        </TopToolbar>
    );
};

const switchAvatar = (record, index) => {

    let returnIcon
    switch (record.type) {
        case 'Exotic':
            returnIcon = <HomeIcon style={{ color: 'rgb(132, 41, 203, 0.5)' }} />
            break;
        case 'Routine':
            returnIcon = <HomeIcon style={{ color: 'rgb(218, 164,188, 0.5)' }} />
            break;
        case 'N1':
            returnIcon = <HomeIcon style={{ color: 'rgb(254, 154, 0, 0.5)' }} />
            break;
        case 'N2':
            returnIcon = <HomeIcon style={{ color: 'rgb(254, 154, 0, 0.5)' }} />
            break;
        case 'N3':
            returnIcon = <HomeIcon style={{ color: 'rgb(254, 154, 0, 0.5)' }} />
            break;
        case 'N4':
            returnIcon = <HomeIcon style={{ color: 'rgb(254, 154, 0, 0.5)' }} />
            break;
        case 'N5':
            returnIcon = <HomeIcon style={{ color: 'rgb(254, 154, 0, 0.5)' }} />
            break;
        case 'N12':
            returnIcon = <HomeIcon style={{ color: 'rgb(255, 255, 2, 0.5)' }} />
            break;
        case 'N23':
            returnIcon = <HomeIcon style={{ color: 'rgb(255, 255, 2, 0.5)' }} />
            break;
        case 'N45':
            returnIcon = <HomeIcon style={{ color: 'rgb(255, 255, 2, 0.5)' }} />
            break;
        case 'Stretch':
            returnIcon = <HomeIcon style={{ color: 'rgb(5, 254,255, 0.5)' }} />
            break;
        case 'Individualni':
            returnIcon = <HomeIcon style={{ color: 'rgb(112, 168, 219, 0.5)' }} />
            break;
        case 'Hoop':
            returnIcon = <HomeIcon style={{ color: 'rgb(33, 237, 91, 0.5)' }} />
            break;
        case 'SummerBody':
            returnIcon = <HomeIcon style={{ color: 'rgb(33, 111, 235, 0.5)' }} />
            break;
        case 'HoopChoreo':
            returnIcon = <HomeIcon style={{ color: 'rgb(33, 237, 91, 0.5)' }} />
            break;
        case 'PoleArt':
            returnIcon = <HomeIcon style={{ color: 'rgb(255, 0, 0, 0.5)' }} />
            break;
        case 'Yoga':
            returnIcon = <HomeIcon style={{ color: 'rgb(5, 254,255, 0.5)' }} />
            break;
        case 'OpenPole':
            returnIcon = <HomeIcon style={{ color: 'rgb(5, 254,255, 0.5)' }} />
            break;
        default:
            returnIcon = <HomeIcon style={{ color: 'grey' }} />
            break;
    }
    return returnIcon

};

const TrainingsColor = (type) => {
    let returnColor = ''
    switch (type) {
        case 'Exotic':
            returnColor = 'rgb(132, 41, 203, 0.5)'
            break;
        case 'Routine':
            returnColor = 'rgb(218, 164,188, 0.5)'
            break;
        case 'N1':
            returnColor = 'rgb(254, 154, 0, 0.5)'
            break;
        case 'N2':
            returnColor = 'rgb(254, 154, 0, 0.5)'
            break;
        case 'N3':
            returnColor = 'rgb(254, 154, 0, 0.5)'
            break;
        case 'N4':
            returnColor = 'rgb(254, 154, 0, 0.5)'
            break;
        case 'N5':
            returnColor = 'rgb(254, 154, 0, 0.5)'
            break;
        case 'N12':
            returnColor = 'rgb(255, 255, 2, 0.5)'
            break;
        case 'N23':
            returnColor = 'rgb(255, 255, 2, 0.5)'
            break;
        case 'N45':
            returnColor = 'rgb(255, 255, 2, 0.5)'
            break;
        case 'Stretch':
            returnColor = 'rgb(5, 254,255, 0.5)'
            break;
        case 'Individualni':
            returnColor = 'rgb(112, 168, 219, 0.5)'
            break;
        case 'Hoop':
            returnColor = 'rgb(33, 237, 91, 0.5)'
            break;
        case 'HoopChoreo':
            returnColor = 'rgb(33, 237, 91, 0.5)'
            break;
        case 'PoleArt':
            returnColor = 'rgb(255, 0, 0, 0.5)'
            break;
        case 'Yoga':
            returnColor = 'rgb(5, 254,255, 0.5)'
            break;
        case 'OpenPole':
            returnColor = 'rgb(5, 254,255, 0.5)'
            break;
        default:
            returnColor = ''
            break;
    }
    return returnColor


}

const SimpleListRowStyle = (record, index) => ({
    backgroundColor: record.studentsPerTraining <= record?.students?.length ? 'rgb(138, 63, 63)' : '',
    borderBottom: moment(record.appointment).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') ? '2px dashed grey' : '',
    borderTop: moment(record.appointment).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') ? '2px dashed grey' : '',
});

const DataGridListRowStyle = (record, index) => ({
    backgroundColor: record.studentsPerTraining <= record?.students?.length ? 'rgb(138, 63, 63)' : '',
    borderBottom: moment(record.appointment).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') ? '2px dashed grey' : '',
    borderTop: moment(record.appointment).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') ? '2px dashed grey' : '',
});

const TextArrayField = ({ record, source }) => (
    <>
        {record[source].map(item => <Chip label={item} key={item} />)}
    </>
)
TextArrayField.defaultProps = { addLabel: true };


export const TrainingList = props => {
    const refresh = useRefresh();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [userMe] = React.useContext(userContext)
    const t = useTranslate();

    React.useEffect(() => {
        socket.on('trainings', (res) => refresh())

        return () => socket.off('trainings');
    }, [])

    return (
        <List title={<TitleTraining />} bulkActionButtons={false} filters={<TrainingFilter />} filterDefaultValues={{ appointment_gte: moment().format("YYYY-MM-DD"), appointment_lte: moment().add(10, 'day').format("YYYY-MM-DD") }} sort={{ field: 'appointment', order: 'ASC' }} {...props}>
            {isSmall ?
                <SimpleList
                    rowStyle={SimpleListRowStyle}
                    leftAvatar={switchAvatar}
                    primaryText={record => `${record.type} - ${record?.students?.length} prijavljenih`}
                    secondaryText={record => moment(record.appointment).format('D/MM/YYYY, HH:mm') + ' - ' + t(moment(record.appointment).format('dddd'))}
                    tertiaryText={record => {
                        let allCoaches = ''
                        record.coaches && record.coaches[0] && record.coaches.map(coach => allCoaches = allCoaches + ` ${coach.id === 111 ? coach.name + ' S.' : coach.name}`)
                        return allCoaches
                    }}
                />
                : <Datagrid rowClick="edit" rowStyle={DataGridListRowStyle} >
                    <DateTimeCustom source="appointment" label="Termin" />
                    {/*<TextField source="location" />*/}
                    <TextField source="type" label="Tip" />
                    <TextArrayField source="levels" label="Nivoi" />
                    <AssignedToTraining label="Br. prijavljenih" />
                    {/* <NumberField source="duration" /> */}
                    <NumberField source="studentsPerTraining" label="Max po treningu" />
                    {/* <TextField source="topic" /> */}
                    <ArrayField source="coaches" label="Treneri" sortable={false}><SingleFieldList linkType={false}><ChipField source="name" /></SingleFieldList></ArrayField>
                </Datagrid>}
        </List>
    )
};

// *****************  TRAINING LIST FOR CLIENTS *************************************

export const TrainingListClients = props => {

    const [userMe] = React.useContext(userContext)
    const windowFocused = useWindowFocus();
    const refresh = useRefresh();
    const [loading, setLoading] = React.useState(false)
    const t = useTranslate();


    React.useEffect(() => {
        socket.on('trainings', (res) => refresh() & setLoading(false));
        return () => socket.off('trainings');
    }, [])

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List bulkActionButtons={false}

            filterDefaultValues={{ appointment_gte: moment().format("YYYY-MM-DD"), appointment_lte: moment().add(1, 'week').format("YYYY-MM-DD"), locked: false, levels_contains: userMe?.student?.level }}
            actions={<ListActions />}
            sort={{ field: 'appointment', order: 'ASC' }} {...props}>
            <SimpleList
                linkType={false}
                rowStyle={SimpleListRowStyle}
                leftAvatar={switchAvatar}
                primaryText={record => record.type === 'Routine' ? 'Dance Routine' : `${record.type}`}
                secondaryText={record => {
                    return (<><span>{moment(record.appointment).format('D/MM/YYYY, HH:mm')
                    }</span>   <br />
                        <span> {t(moment(record.appointment).format('dddd'))}</span></>)
                }}
                tertiaryText={record => {
                    return <AssignClient record={record} loading={loading} setLoading={setLoading} />
                }}
            />
        </List>
    )
};

// ***************************************************

export const TrainingEdit = props => {

    const [isLogged, setIsLogged] = React.useState(false)
    const [NoteDialog, setNoteDialog] = React.useState(false)

    const [userMe,] = React.useContext(userContext)
    const refresh = useRefresh();

    React.useEffect(() => {
        socket.on('trainings', (res) => refresh())

        return () => socket.off('trainings');
    }, [])

    return (
        <Edit title={<Title />} {...props} undoable={false}>
            <SimpleForm toolbar={false}>

                <AssignButton
                    source="coaches"
                    setIsLogged={setIsLogged}
                    isLogged={isLogged}
                    setNoteDialog={setNoteDialog}
                    disabled={isLogged ? true : false}
                />


                <FormDataConsumer>
                    {({ formData }) => <React.Fragment>
                        <ReferenceArrayInput label="Treneri" reference="users" source="coaches">
                            <CheckboxGroupInput options={{
                                icon: <PersonIcon />,
                                checkedIcon: <PersonIcon />
                            }} choices={formData && formData.coaches && formData.coaches[0] && formData.coaches.map(x => {
                                if (x) return ({ id: x.id, name: x.name })
                            })} disabled />
                        </ReferenceArrayInput>

                        <Box style={{ display: 'flex' }}>
                            <TextInput source="everything" /*label="Location and time"*/ label="Termin" format={() => moment(formData.appointment).format('D/MM/YYYY, HH:mm') /*+ ' - ' + formData.location */} disabled />
                            <TextInput source="levels" label="Nivoi" style={{ marginLeft: 10 }} disabled />
                        </Box>
                        {/*  <TextInput source="topic" disabled multiline fullWidth /> */}
                        <Box style={{ display: 'flex' }}>
                            <NumberInput source="studentsPerTraining" label="Max po treningu" disabled />
                            <SelectInput style={{ marginLeft: 10 }} source="type" disabled label="Tip" choices={[
                                { id: 'N1', name: 'N1' },
                                { id: 'N2', name: 'N2' },
                                { id: 'N3', name: 'N3' },
                                { id: 'N4', name: 'N4' },
                                { id: 'N5', name: 'N5' },
                                { id: 'N12', name: 'N12' },
                                { id: 'N23', name: 'N23' },
                                { id: 'N45', name: 'N45' },
                                { id: 'Routine', name: 'Routine' },
                                { id: 'Exotic', name: 'Exotic' },
                                { id: 'Stretch', name: 'Stretch' },
                                { id: 'Individualni', name: 'Individualni' },
                                { id: 'Hoop', name: 'Hoop' },
                                { id: 'SummerBody', name: 'SummerBody' },
                                { id: 'HoopChoreo', name: 'HoopChoreo' },
                                { id: 'PoleArt', name: 'PoleArt' },
                                { id: 'Instructory', name: 'Instructory' },
                                { id: 'Yoga', name: 'Yoga' },
                                { id: 'OpenPole', name: 'OpenPole' }
                            ]} />
                        </Box>
                        <Dialog open={NoteDialog} fullWidth >
                            <Box style={{ margin: '10px' }}>
                                <ArrayInput source="Notes" label="Beleška">
                                    <SimpleFormIterator>
                                        <ReferenceInput label="Trener" source="author" reference="users" filter={{ id: userMe && userMe.id }}>
                                            <SelectInput optionText="name" optionValue="id" fullWidth />
                                        </ReferenceInput>
                                        <TextInput source="message" multiline label="Poruka" fullWidth />
                                    </SimpleFormIterator></ArrayInput>
                                <Box display='flex' justifyContent='end' paddingTop='30px'>
                                    <SaveButtonNotes setNoteDialog={setNoteDialog} formData={formData} />
                                    <Button style={{ marginLeft: '20px' }} variant="contained" color="primary" onClick={() => setNoteDialog(false)}>
                                        Zatvori
                                    </Button>
                                </Box>
                            </Box>
                        </Dialog>

                        <AddStudent formData={formData} />
                        {formData.Notes && formData.Notes[0] ? <Badge badgeContent={'N'} color="error"> <Button style={{ marginLeft: 10 }}/*disabled={isLogged ? false : true}*/ variant="contained" color="primary" onClick={() => setNoteDialog(true)}>
                            Beleška
                        </Button></Badge>
                            :
                            <Button variant="contained" color="primary" style={{ marginLeft: 10 }} /*disabled={isLogged ? false : true} */ onClick={() => setNoteDialog(true)}>
                                Beleška
                            </Button>
                        }
                        <ChangeForm formData={formData} />
                        <ItemsTable
                            fields={['Ime']}
                            formData={formData}
                            data={formData.students && formData.students.sort((a, b) => a.firstName.localeCompare(b.firstName))}
                        />
                    </React.Fragment>}
                </FormDataConsumer>


            </SimpleForm>
        </Edit>
    )
};

export const TrainingCreate = props => {

    const [openTopic, setOpenTopic] = React.useState(false)

    return (
        <Create {...props} undoable={false}>
            <SimpleForm redirect="list">
                <DateTimePicker source="appointment" type="DateAndTime" label="Termin" />
                {/*  <SelectInput source="location" choices={[
                    { id: 'DIF', name: 'DIF' },
                    { id: 'Arena', name: 'Arena' },
                    { id: 'NBG', name: 'NBG' },
                    { id: 'Hram', name: 'Hram' },
                ]} /> */}
                <SelectInput source="type" label="Tip" initialValue={"N1"} choices={[
                    { id: 'N1', name: 'N1' },
                    { id: 'N2', name: 'N2' },
                    { id: 'N3', name: 'N3' },
                    { id: 'N4', name: 'N4' },
                    { id: 'N5', name: 'N5' },
                    { id: 'N12', name: 'N12' },
                    { id: 'N23', name: 'N23' },
                    { id: 'N45', name: 'N45' },
                    { id: 'Routine', name: 'Routine' },
                    { id: 'Exotic', name: 'Exotic' },
                    { id: 'Stretch', name: 'Stretch' },
                    { id: 'Individualni', name: 'Individualni' },
                    { id: 'Hoop', name: 'Hoop' },
                    { id: 'SummerBody', name: 'SummerBody' },
                    { id: 'HoopChoreo', name: 'HoopChoreo' },
                    { id: 'PoleArt', name: 'PoleArt' },
                    { id: 'Instructory', name: 'Instructory' },
                    { id: 'Yoga', name: 'Yoga' },
                    { id: 'OpenPole', name: 'OpenPole' }
                ]} />
                <SelectInput source="duration" label="Trajanje" initialValue={70} choices={[
                    { id: 70, name: '70 min' },
                    { id: 60, name: '60 min' },
                ]} />

                {/*  <TextInput source="topic" multiline fullWidth /> */}
                <NumberInput source="studentsPerTraining" label="Max broj studenata" initialValue={6} />
                <SelectArrayInput label="Nivoi" source="levels" choices={[
                    { id: 'N1', name: 'N1' },
                    { id: 'N2', name: 'N2' },
                    { id: 'N3', name: 'N3' },
                    { id: 'N4', name: 'N4' },
                    { id: 'N5', name: 'N5' },
                    { id: 'Individualni', name: 'Individualni' },
                    { id: 'R', name: 'R' },
                    { id: 'Y', name: 'Y' },
                ]} />
                <FormDataConsumer>
                    {({ formData }) => <React.Fragment>
                        <CheckboxGroupInput source="repeat" label="" choices={[
                            { id: 'Repeat', name: 'Ponavljanje' },
                        ]} />

                        {formData.repeat && formData.repeat[0] === 'Repeat' && <ListAppointments formData={formData} />}

                    </React.Fragment>}
                </FormDataConsumer>

                {/*<Button variant="contained" color="primary" onClick={() => setOpenTopic(true)}>
                    Generate
                                </Button>
                <GenerateDialog openTopic={openTopic} setOpenTopic={setOpenTopic} /> */}
            </SimpleForm>
        </Create>
    )
};