import * as React from "react";
import { List, Create, SimpleList, Datagrid, Filter, TextField, DateField, NumberField, FormDataConsumer, SimpleForm, TextInput, NumberInput, Edit, ReferenceInput, SelectInput } from 'react-admin';
import DatePicker from '../components/DateTimePicker'
import { useForm } from 'react-final-form';
import Box from '@material-ui/core/Box';
import { useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { DateInput } from '../components/DateInput'

const Title = ({ record }) => {
    return <span>{record.coach.name}</span>;
};

const TitleSalary = ({ record }) => {
    return <span>Plate</span>;
};

const SaleriesFilter = (props) => {

    return (
        <Filter {...props}>
            <TextInput source="_q" label="Pretraži" alwaysOn />
            <DateInput source='created_at_gte' label="Od" alwaysOn options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />
            <DateInput source='created_at_lte' label="Do" alwaysOn options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />
        </Filter>
    )
};

export const SalaryList = props => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List title={<TitleSalary />} sort={{ field: 'created_at', order: 'DESC' }} filters={<SaleriesFilter />} bulkActionButtons={false} {...props}>
            {isSmall ?
                <SimpleList
                    primaryText={record => record.coach.name}
                    secondaryText={record => moment(record.startDate).format('D/MM/YYYY') + ' - ' + moment(record.endDate).format('D/MM/YYYY')}
                    tertiaryText={record => record.total + ' RSD'}
                /> : <Datagrid rowClick="edit">
                    <TextField source="coach.name" label='Trener' />
                    <DateField source="startDate" locales="fr-FR" label='Od' />
                    <DateField source="endDate" locales="fr-FR" label='Do' />
                    <NumberField source="totalHours" label="Broj treninga" />
                    <NumberField source="salary" label="Po satu (RSD)" />
                    <NumberField source="bonus" />
                    <NumberField source="total" label='Ukupno' />
                    <DateField source="created_at" locales="fr-FR" label='Kreirano' />
                </Datagrid>}
        </List>
    )
};

export const SalaryEdit = props => (
    <Edit title={<Title />}  {...props} undoable={false}>
        <SimpleForm>
            <TextInput source="coach.name" label='Coach' disabled />
            <DatePicker source="startDate" type="dateOnly" />
            <DatePicker source="endDate" type="dateOnly" />
            <DateInput source="startDate" options={{ format: 'DD/MM/YYYY' }} inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }} />
            <DateInput source="endDate" options={{ format: 'DD/MM/YYYY' }} inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }} />
            <NumberInput source="totalHours" label="Broj treninga" inputVariant="filled" margin="dense" />
            <NumberInput source="salary" label="Per hour" />
            <NumberInput source="bonus" />
            <NumberInput source="total" />
            <DateInput source="created_at" disabled options={{ format: 'DD/MM/YYYY' }} inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }} />
        </SimpleForm>
    </Edit>
);

export const SalaryCreate = props => {

    const SalaryInput = props => {

        const form = useForm()

        return (
            <NumberInput label="Cena po satu (RSD)" {...props} onChange={(e) => {
                const total = parseFloat(e.target.value * props.formData.totalHours + props.formData.bonus)
                form.change('total', total)
            }} />
        )
    }

    const TotalHoursInput = props => {
        const form = useForm()

        return (
            <NumberInput {...props} onChange={(e) => {
                const total = parseFloat(e.target.value * props.formData.salary + props.formData.bonus)
                form.change('total', total)
            }} />
        )
    }

    const BonusInput = props => {
        const form = useForm()
        return (
            <NumberInput  {...props} onChange={(e) => {
                const total = parseFloat(parseFloat(e.target.value) + props.formData.salary * props.formData.totalHours)
                form.change('total', total)

            }} />
        )
    }

    const ListTypeOfTrainings = props => {
     const typeOfTrainingList = props?.formData?.typeOfTrainigs
     
      const countElements = typeOfTrainingList && Object.keys(typeOfTrainingList).map((item) => (
        <li  key={item}>
          {item}: {typeOfTrainingList[item]}
        </li>
      ));
        return (
            <div>{countElements}</div>
        )
    }

    return (
        <Create {...props} undoable={false}>
            <SimpleForm redirect="list">
                <ReferenceInput label="Izaberi trenera" source="coach" reference="users" filter={{ role_ne: 2 }}>
                    <SelectInput optionText="name" optionValue='id' />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData }) => <>
                        {formData.coach && <React.Fragment>
                            <Box display='flex' flexDirection='column' width='260px'>
                                <Box marginBottom="25px">
                                    <DatePicker source="startDate" type="dateOnly" label="Od" formData={formData} coachID={formData.coach} />
                                </Box>
                                <Box marginBottom="25px">
                                    <DatePicker source="endDate" type="dateOnly" label="Do" formData={formData} coachID={formData.coach} />
                                </Box>
                                {/*    <NumberInput source="totalTrainings" defaultValue={0} label="Broj treninga" disabled={true} /> */}
                               <ListTypeOfTrainings source="typeOfTrainigs" formData={formData} />
                                <TotalHoursInput source="totalHours" label="Broj treninga" defaultValue={0} formData={formData} />
                                <SalaryInput source="salary" defaultValue={0} formData={formData} />
                                <BonusInput source="bonus" defaultValue={0} formData={formData} />
                                <NumberInput source="total" defaultValue={0} label="Ukupno" />
                            </Box>
                        </React.Fragment>}
                    </>}
                </FormDataConsumer>


            </SimpleForm>
        </Create>
    )
}