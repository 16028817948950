import * as React from 'react';
import { AppBar, UserMenu, MenuItemLink, usePermissions } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';



export const MyUserMenu = props => {

    const { permissions } = usePermissions();

    return (
        <UserMenu {...props}>
  
        </UserMenu>
    )
};

