import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#42b6ab',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(1, 0, 1),
        backgroundColor: '#42b6ab !important'
    },
    submitDialog: {
        backgroundColor: '#42b6ab !important'
    },
    input: {
        '&::placeholder': {
            color: 'grey',
        },
        'color': 'white'
    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: "#42b6ab !important"
    },
    link: {
        color: "#42b6ab !important"
    },
    formControl: {

    },

}));


export default function Instructions() {
    const classes = useStyles();

    return (

        <Container component="main" maxWidth="xs">

            <div className={classes.paper} tyle={{ backgroundColor: 'white !important' }}>
                <Avatar className={classes.avatar}>
                    <HelpIcon />
                </Avatar>
                <Typography style={{ marginBottom: 30 }} component="h1" variant="h5">
                    Uputstvo
                </Typography>

                <Grid container spacing={2}>
                    <Typography variant="body2" style={{ marginBottom: 10 }}>
                        Draga naša,
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: 5 }}>
                        Članarina koju uplatiš traje tačno <strong>mesec dana</strong>, od dana uplate do sledećeg meseca na taj dan. (npr. 5.5. do 5.6.). Svoje treninge možeš iskoristiti samo u tom periodu. Članarina se odnosi na sve naše tipove treninga, koje sama kombinuješ kako tebi odgovara.
                       <strong> Nakon isteka tih mesec dana, članarina se briše i treninzi se ne prenose u naredni mesec. </strong> 🌈
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: 5 }}>
                        Na App-i imaš polja sa svim informacijama u vezi sa svojim profilom, kao i cenovnik, i sva pravila. Uvek možeš da pogledaš datum uplate, kada ti ističe članarina, broj treninga koji ti je ostao, i kada treba da platiš narednu članarinu.
                        Treninge možeš videti 6 dana unapred i <strong>možeš ih zakazati bez obzira da li imaš uplaćenu članarinu</strong>. 
                        Možeš zakazati i više treninga od uplaćenih, jer će ih softver obračunati i naplatiti prilikom uplate sledeće članarine. <strong>Otkazivanje treninga je moguće najkasnije dan ranije, ne može se otkazati istog dana kada je trening zakazan, u suprotnom App računa trening kao iskorišćen.</strong>. 😇
                    </Typography>
                    <Typography variant="body2" >
                    Ako prodje mesec dana od poslednjeg treninga koji si zakazala, softver će sam da te deaktivira, pa bi bilo idealno da budeš redovna. Preporučujemo ti da se zakažeš za trening par dana unapred, kako bi sebi obezbedila mesto jer su grupe male i brzo se popunjavaju. 
                    </Typography>
                    <Typography variant="body2">
                        Za državne praznike ne zamrzavamo članarine, osim ako pravimo pauze van praznika i kada su godišnji odmori. 🌸
                    </Typography>
                    <Typography variant="body2">
                    Članarinu je moguće uplatiti online, ali uplata mora biti izvršena na dan kada počinje članarina, odnosno pre, ili nakon zakazanog treninga tog dana. Takodje je potrebno poslati potvrdu o uplati na instagram profil našeg studija. 
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: 10 }}>
                    Ovo je Web app, tako da se <strong>ne može instalirati na telefonu</strong>, ali se može izvući kao prečica na home screan (googluj kako, za svaki telefon je različito).
                    </Typography>
                    <Typography variant="body2" >
                        Tu smo za sva pitanja i informacije, uživaj sa nama! 💖
                    </Typography>
                    <Grid item xs={12}>
                    </Grid>
                </Grid>

            </div>
        </Container>

    );
}