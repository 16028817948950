import React, { forwardRef } from 'react';
import { DashboardMenuItem, MenuItemLink } from 'react-admin';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PaymentIcon from '@material-ui/icons/Payment';
import { useLogout } from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import HelpIcon from '@material-ui/icons/Help';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const MyLogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();
    const handleClick = () => logout();
    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
        >
            <ExitIcon style={{ marginRight: 18 }} /> Odjavi se
        </MenuItem>
    );
});

export const ClientMenu = () => (
    <div style={{marginTop: 20}}>
        <MenuItemLink to="/Trainings" primaryText="Zakaži trening" leftIcon={<SportsKabaddiIcon />} />
        <MenuItemLink to="/instructions" primaryText="Uputstvo" leftIcon={<HelpIcon />} />
        <MenuItemLink to="/prices" primaryText="Cenovnik" leftIcon={<AttachMoneyIcon />} />
        <MenuItemLink to="/myprofile" primaryText="Moj profil" leftIcon={<SettingsIcon />} />
        <MyLogoutButton />
    </div>
);