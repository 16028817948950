import { saveAs } from "file-saver";
import moment from 'moment';
var vCardJS = require("vcards-js");
var vCard = vCardJS();
var fullVCard = ''

function makeContact(firstName, lastName, phone, email) {
    vCard.firstName = firstName;
    vCard.lastName = lastName;
    vCard.cellPhone = phone;
    vCard.email = email;
    if (fullVCard === '') fullVCard = vCard.getFormattedString()
    else fullVCard += '\n' + vCard.getFormattedString();
}

export default function exportToVCard(data) {

    data && data.map(student => makeContact(student.firstName, student.lastName, student.phone, student.email))

    let blob = new Blob([fullVCard], {
        type: "text/vcard"
    });
    saveAs(blob, moment().format('D/MM/YYYY') + '.vcf');

}

