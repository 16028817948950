import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Dialog } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { newUser } from '../Services/Services'
import {
    DatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#42b6ab',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#42b6ab !important'
    },
    submitDialog: {
        backgroundColor: '#42b6ab !important'
    },
    input: {
        '&::placeholder': {
            color: 'grey',
        },
        'color': 'black'
    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: "#42b6ab !important"
    },
    link: {
        color: "#42b6ab !important"
    },
    formControl: {

    },

}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #42b6ab',
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#42b6ab',

        },
    },
}))(InputBase);

export default function SignUp(props) {
    const classes = useStyles();
    const [showPassword, setShowPassword] = React.useState(false)
    const [agrementDialog, setAgrementDialog] = React.useState(false)
    const [checked, setChecked] = React.useState(false);
    const [data, setData] = React.useState({
        level: 'N1',
        firstName: '',
        lastName: '',
        phone: '',
        birthday: moment().format('YYYY-MM-DD'),
        email: '',
        password: '',
    })



    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return (
        <Dialog open={props.openSignUpDialog} fullScreen fullWidth onBackdropClick={() => props.setOpenSignUpDialog(false)}>
            <Container component="main" maxWidth="xs">

                <div className={classes.paper} tyle={{ backgroundColor: 'white !important' }}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography style={{ marginBottom: 5 }} component="h1" variant="h5">
                        Registracija
                    </Typography>
                    <form className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="fname"
                                    name="firstName"
                                    variant="outlined"
                                    value={data.firstName}
                                    onChange={e => setData({ ...data, firstName: e.target.value })}
                                    required
                                    fullWidth
                                    id="firstName"
                                    placeholder="Ime"
                                    autoFocus
                                    InputProps={{
                                        classes: {
                                            input: classes.input,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="lastName"
                                    value={data.lastName}
                                    onChange={e => setData({ ...data, lastName: e.target.value })}
                                    placeholder="Prezime"
                                    name="lastName"
                                    autoComplete="lname"
                                    InputProps={{
                                        classes: {
                                            input: classes.input,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="phone"
                                    placeholder="Telefon"
                                    value={data.phone}
                                    onChange={e => setData({ ...data, phone: e.target.value })}
                                    name="phone"
                                    autoComplete="phone"
                                    InputProps={{
                                        classes: {
                                            input: classes.input,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DatePicker
                                    id="date-picker-dialog"
                                    variant="dialog"
                                    inputVariant="outlined"
                                    InputAdornmentProps={{ position: "start" }}
                                    label="Datum rodjenja"
                                    style={{ width: '100%' }}
                                    invalidDateMessage=''
                                    format="DD/MM/YYYY"
                                    InputProps={{
                                        classes: {
                                            input: classes.input,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                    value={data.birthday}
                                    onChange={date => setData({ ...data, birthday: moment(date.toISOString()).format('YYYY-MM-DD') })
                                    }
                                    KeyboardButtonProps={{
                                        'aria-label': 'Birthday',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
                                    <Select
                                        displayEmpty={false}
                                        id="levelInput"
                                        value={data.level}
                                        onChange={e => setData({ ...data, level: e.target.value })}
                                        input={<BootstrapInput />}

                                    >
                                        <MenuItem value='N1'>N1 - Početni nivo</MenuItem>
                                        <MenuItem value='N2'>N2 - Srednji nivo</MenuItem>
                                        <MenuItem value='N3'>N3 - Napredni nivo</MenuItem>
                                        <MenuItem value='N4'>N4 - Napredni nivo</MenuItem>
                                        <MenuItem value='N5'>N5 - Napredni nivo</MenuItem>
                                        <MenuItem value='Individualni'>Individualni</MenuItem>
                                        <MenuItem value='R'>Ruska grupa</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    placeholder="Email"
                                    name="email"
                                    value={data.email}
                                    onChange={e => setData({ ...data, email: e.target.value })}
                                    autoComplete="email"
                                    InputProps={{
                                        classes: {
                                            input: classes.input,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    placeholder="Šifra"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    value={data.password}
                                    onChange={e => setData({ ...data, password: e.target.value })}
                                    autoComplete="current-password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    style={{ color: '#42b6ab ' }}>
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>)
                                        ,
                                        classes: {
                                            input: classes.input,
                                            notchedOutline: classes.notchedOutline
                                        },

                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox
                                        checked={checked}
                                        style={{
                                            color: '#42b6ab',
                                        }}
                                        onChange={(event) => setChecked(event.target.checked)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />}
                                        label={<>
                                            <Typography variant="body">Prihvatam <Link onClick={() => setAgrementDialog(true)}>uslove</Link> </Typography>

                                        </>}
                                    />

                                </FormGroup>

                            </Grid>
                        </Grid>

                        <Dialog open={agrementDialog} fullScreen fullWidth onBackdropClick={() => setAgrementDialog(false)}>
                            <Container component="main" maxWidth="xs">

                                <div className={classes.paper} tyle={{ backgroundColor: 'white !important' }}>

                                    <Grid container justify="flex-end">
                                        <Grid item>
                                            <Link style={{ cursor: 'pointer' }} onClick={() => setAgrementDialog(false)} variant="body2">
                                                Vrati se nazad
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    <Typography variant="h6" style={{ paddingBottom: 20 }}>
                                        Udruženje “Pole Dance House Boutique Studio”   <br />
                                    </Typography>

                                    <Typography variant="h6" style={{ paddingBottom: 20 }}>
                                        Saglasnost
                                    </Typography>

                                    <Typography variant="body" style={{ paddingBottom: 20 }}>
                                        Cilj programa je da polaznicima prema unapred određenom planu i programu pruži mogućnosti pravilnog upoznavanja i treniranja veštine Pole Dance.
                                        Saglasna sam da pohađam navedene treninge i preuzimam svu odgovornost za eventualne posledice do kojih može doći ukoliko se ne budem pridržavala pravila upražnjavanja veštine Pole Dance kako na treninzima, tako i van njih.
                                        Organizator zadržava pravo da stupi u vezu sa potpisnikom ove saglasnosti bez ograničenja ili prethodne najave.
                                        Prema planu rada polaznik će biti informisan o osnovama fizičke kulture koje su baza za bezbedno bavljenje bilo kojom fizičkom aktivnošću kao i osnovama i metodama vežbanja veštine Pole Dance.
                                    </Typography>

                                    <Typography variant="body" style={{ paddingBottom: 20 }}>
                                        Vaš Pole Dance House Boutique Studio
                                    </Typography>

                                    <Grid container justify="flex-end">
                                        <Grid item>
                                            <Link style={{ cursor: 'pointer' }} onClick={() => setAgrementDialog(false)} variant="body2">
                                                Vrati se nazad
                                            </Link>
                                        </Grid>
                                    </Grid>

                                </div>
                            </Container>
                        </Dialog>

                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => {
                                if (checked === false) return alert('Morate da prihvatite uslove korišćenja.')
                                if (data.firstName === '' || data.lastName === '' || data.password === '' || data.email === '' || data.birthday === '' || data.phone === '') {
                                    alert('Morate da popunite sva polja.')
                                } else {
                                    newUser({ ...data, username: data.email })
                                        .then(res => alert('Vaš nalog je kreiran. Poslali smo vam email sa aktivacionim linkom. Prvo morate da aktivirate nalog (idite u vaš email inbox), a zatim možete da se ulogujete.') & props.setOpenSignUpDialog(false))
                                        .catch(error => {
                                            alert(error.response.data.message[0].messages[0].message)
                                        })
                                }
                            }}
                        >
                            Registruj se
                        </Button>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link style={{ cursor: 'pointer' }} onClick={() => props.setOpenSignUpDialog(false)} variant="body2">
                                    Već imate nalog? Prijavi se
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        </Dialog>
    );
}