import React from 'react';
import TableListPayment from './TableListPayment'
import { getPayments } from '../../Services/Services'
import { Dialog } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

export default function ListPayments(props) {

    const [studentPayment, setStudentPayment] = React.useState()

    React.useEffect(() => {

        props.studentID && getPayments(props.studentID).then(data => {
            setStudentPayment(data)
        })
    }, [])

    return (<>
        <Dialog open={props.paymentDialog} fullWidth>
            <Box style={{ margin: '10px' }}>

                {
                    studentPayment && <TableListPayment
                        fields={['Iznos', 'Period', 'Datum plaćanja']}
                        data={studentPayment} />}
            </Box>
            <Button style={{ marginLeft: '20px' }} variant="contained" color="primary" onClick={() => props.setPaymentDialog(false)}>
                Zatvori
    </Button>
        </Dialog>
    </>
    )
}
